// Utils
import getRequestConfig from '../../functions/getRequestConfig';
import axios from 'axios';
// Types
import { UserInfo } from '@trii/types/dist/Users';
async function fetch(jwtToken: string, URL_USERS: string) {
  const config = getRequestConfig.basic(jwtToken);
  const response = await axios.get<UserInfo[]>(URL_USERS, config);

  return response.data;
}

export default {
  fetch,
};
