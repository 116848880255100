import { useState } from 'react';

export type UseRadio = {
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  reset: () => void;
  changeValue: (newValue: string) => void;
};

const useRadio = (initialValue: string) => {
  const [value, setValue] = useState(initialValue);

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };

  const changeValue = (newValue: string) => {
    setValue(newValue);
  };

  const reset = () => {
    setValue(initialValue);
  };

  return {
    value,
    onChange,
    reset,
    changeValue,
  };
};

export default useRadio;
