// Components/io
import {
  Box,
  RadioGroup,
  FormControl,
  FormControlLabel,
  Radio,
  Typography,
} from '@mui/material';
import { style } from './style';
// Types
import { UserInfo } from '@trii/types/dist/Users';
import { UseRadio } from '../../../../../../../../../../../../../../hooks/useRadio';

type CallListProps = {
  callSelector: UseRadio;
  callOptions: UserInfo[] | { id: number; name: string }[];
};
const CallList = ({ callSelector, callOptions }: CallListProps) => {
  return (
    <FormControl sx={style.container}>
      <RadioGroup {...callSelector}>
        {callOptions.map((option) => (
          <FormControlLabel
            key={option.id}
            value={option.sipNumber || option.name}
            sx={style.formControlLabel}
            control={
              <Radio
                size="small"
                onClick={(event) => {
                  if (callSelector.value === option.name) {
                    event.stopPropagation();
                    callSelector.changeValue('');
                  }
                }}
              />
            }
            label={
              <Box sx={style.label}>
                <Typography color={'text.primary'} variant="caption">
                  {option.name}
                </Typography>
                <Typography color={'text.secondary'} variant="caption">
                  {option.sipNumber}
                </Typography>
              </Box>
            }
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
};

export default CallList;
