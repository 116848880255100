// Components/ui
import { style } from './style';
import { Box, TextField, InputAdornment } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { ActionButtons, CloseButton } from './components';
// Types
import { UseField } from '../../../../../../../../../../../../../../hooks/useField';

type HeaderProps = {
  searchField: UseField;
};

const Header = ({ searchField }: HeaderProps) => {
  return (
    <Box sx={style.container}>
      <CloseButton />
      <ActionButtons />
      <TextField
        placeholder="Buscar"
        variant="outlined"
        size="small"
        {...searchField}
        sx={{ width: '100%' }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="end">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />
    </Box>
  );
};

export default Header;
