// Context
import SipContext from '../../../../../../../../../../context/Sip/SipContext';
import { StyleContext } from '../../../../../../../../../../style/styleProvider';
import { useContext } from 'react';
// Components/ui
import { Box } from '@mui/material';
import { ActionButtons } from './components';
import { DialPad, DialVisor } from '../../layout';
import { style } from './style';

const Dial = () => {
  const {
    dial: { handleChange, dialValue, handleDialBackspace },
    handleMakeCall,
    handleDialPress,
  } = useContext(SipContext);
  const { mediaQuery } = useContext(StyleContext);

  return (
    <Box
      sx={{
        ...style.container,
        gap: mediaQuery.sm || mediaQuery.md ? '15px' : '20px',
      }}
    >
      <DialVisor value={dialValue} handleChange={handleChange} />
      <DialPad handleDial={handleDialPress} />
      <ActionButtons
        handleMakeCall={handleMakeCall}
        handleDialBackspace={handleDialBackspace}
      />
    </Box>
  );
};

export default Dial;
