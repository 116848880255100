import { useTranslation } from 'react-i18next';
// Components/ui
import { Box, Typography } from '@mui/material';
import { style } from './style';
// Redux
import { useSelector } from 'react-redux';
import { selectUser } from '../../../../../../../../redux/features/userSlice/userSlice';
// SIP
import * as SIP from 'sip.js';

const ConnectionInfo = () => {
  const { t } = useTranslation();
  const user = useSelector(selectUser);

  // @ts-ignore
  const registerer = window.trii?.softphone?.registerer as SIP.Registerer;
  const { state } = registerer ?? { state: undefined };

  return (
    <Box sx={style.container}>
      <Typography color={'text.primary'} variant={'caption'}>
        {user.sipExtensionConfig?.numberAlias}
      </Typography>
      <Typography color={'text.primary'} variant={'caption'}>
        {state === SIP.RegistererState.Registered
          ? t('calls.connectedTransport')
          : t('calls.disconnectedTransport')}
      </Typography>
    </Box>
  );
};

export default ConnectionInfo;
