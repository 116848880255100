export function onRefer(data) {
  console.log('onRefer', data);
}
export function onMessage(data) {
  console.log('onMessage', data);
}
export function onNotify(data) {
  console.log('onNotify', data);
}
export function onRegister(data) {
  console.log('onRegister', data);
}
export function onSubscribe(data) {
  console.log('onSubscribe', data);
}
export function onReferRequest(data) {
  console.log('onReferRequest', data);
}
export function onRegisterRequest(data) {
  console.log('onRegisterRequest', data);
}
export function onSubscribeRequest(data) {
  console.log('onSubscribeRequest', data);
}
export function onStateChange(newState) {
  console.log('onStateChange', newState);
}
export function onCallHold(session) {
  console.log('onCallHold:', session);
}

export function onAcceptHold(data) {
  console.log('onAcceptHold', data);
}
export function onRejectHold(data) {
  console.log('onRejectHold', data);
}

export function getExtraHeaders(session) {
  return [
    'P-Asserted-Identity: ' + session.remoteIdentity.uri.toString(),
    'Content-Disposition: session;handling=optional',
    'Allow: INVITE, ACK, CANCEL, BYE, UPDATE, PRACK, REFER, INFO, SUBSCRIBE, NOTIFY, PUBLISH, MESSAGE',
    'Supported: replaces, 100rel, timer, norefersub',
  ];
}
