// Components/ui
import { Box, useTheme } from '@mui/material';
import { Message } from './components';

interface Props {
  messages: Message[];
}

const Body = ({ messages }: Props) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        // width: '100%',
        borderTop: `1px solid ${theme.palette.divider}`,
        backgroundColor: 'dropdownBox',
        display: 'flex',
        height: '320px',
        flexDirection: 'column',
        padding: '0.5rem',
      }}
    >
      {messages.map((message) => (
        <Message key={message.id} data={message} />
      ))}
    </Box>
  );
};

export default Body;
