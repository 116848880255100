import { keyframes } from '@mui/material';
export const style = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    paddingRight: '3rem',
  },
};
export function slideIn(amountOfItems: number) {
  return keyframes`
    0% {
      height: 0;
    }
    100% {
      height:${amountOfItems * 2}rem};
    }
  `;
}

export function slideOut(amountOfItems: number) {
  return keyframes`
    0% {
      height:${amountOfItems * 2}rem;
    }
    100% {
      height: 0;
    }
  `;
}
