// Translation
import { useTranslation } from 'react-i18next';
// Components/ui
import { Box, IconButton, Typography } from '@mui/material';
import { phoneIconStyle, style } from './style';
import PhoneIcon from '@mui/icons-material/Phone';
import { useContext } from 'react';
import SipContext from '../../../../../../context/Sip/SipContext';
import { CallStatus } from '../../../../../../context/Sip/hooks/useUserAgent/types/CallStatus';

type OpenDrawerButtonProps = {
  onClick: () => void;
};

const OpenDrawerButton = ({ onClick }: OpenDrawerButtonProps) => {
  const { t, i18n } = useTranslation();
  const isEnglish = i18n.language === 'En';
  const {
    userAgent: { callStatus },
  } = useContext(SipContext);
  return (
    <Box onClick={onClick} sx={style.button}>
      <Box
        sx={{
          ...style.textContainer,
          margin: !isEnglish && "-0.7rem",
          marginRight: !isEnglish && "-1.2rem",
          marginLeft: isEnglish ? "-0.3rem" : "-1.3rem",
          marginTop: callStatus === CallStatus.Calling ? "-3rem" : null,
        }}
      >
        <Typography color={"white"}>{t("calls.calls")}</Typography>
      </Box>
      <Box>
        {callStatus === CallStatus.Calling ? (
          <IconButton sx={phoneIconStyle}>
            <PhoneIcon />
          </IconButton>
        ) : null}
      </Box>
    </Box>
  );
};

export default OpenDrawerButton;
