import { createSelector } from '@reduxjs/toolkit';
// Types
import { RootState } from '../../rootReducer';

const chatsState = (state: RootState) => state.Chats;

export const selectChats = createSelector(chatsState, (chats) => chats.chats);
export const selectOpenChatIds = createSelector(
  chatsState,
  (chats) => chats.openChatIds
);
export const selectOpenChats = createSelector(
  [selectChats, selectOpenChatIds],
  (chats, openChatIds) =>
    openChatIds.map((id) => chats.find((chat) => chat.id === id))
);
export const selectIsChatOpen = (chatId: string) =>
  createSelector(selectOpenChatIds, (openChatIds) => openChatIds.includes(chatId));
