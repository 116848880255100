import { useState } from 'react';
// Types
import { CallInfo, UseCallNotification } from './useCallNotification.type';

const useCallNotification = (): UseCallNotification => {
  const [show, setShow] = useState(false);
  const [callInfo, setCallInfo] = useState<CallInfo>(null);

  function handleShow() {
    setShow(true);
  }

  function handleHide() {
    setShow(false);
  }

  return {
    show,
    handleShow,
    handleHide,
    callInfo,
    setCallInfo,
  };
};

export default useCallNotification;
