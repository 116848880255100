// Components/ui
import { IconButton } from '@mui/material';
import { style } from './style';
import CloseIcon from '@mui/icons-material/Close';

type CloseButtonProps = {
  handleHide: () => void;
};
const CloseButton = ({ handleHide }: CloseButtonProps) => {
  return (
    <IconButton onClick={handleHide} sx={style.button}>
      <CloseIcon sx={style.icon} />
    </IconButton>
  );
};

export default CloseButton;
