import { useState } from 'react';
import { useTranslation } from 'react-i18next';
// Components/ui
import { PhoneTabPanel } from '../../layout';
import { Box, Typography } from '@mui/material';
import { NavigationButton, InternalContacts, ExternalContacts } from './components';
import { style } from './style';

type ContactsPanelProps = {
  value: number;
};
const ContactsPanel = ({ value }: ContactsPanelProps) => {
  const { t } = useTranslation();
  const [currentlyShowing, setCurrentlyShowing] = useState<'internal' | 'external'>(
    'internal'
  );
  const panelTitle =
    currentlyShowing === 'internal'
      ? t('calls.internalContacts')
      : t('calls.externalContacts');
  const contacts =
    currentlyShowing === 'internal' ? <InternalContacts /> : <ExternalContacts />;

  function showExternalContacts() {
    setCurrentlyShowing('external');
  }

  function showInternalContacts() {
    setCurrentlyShowing('internal');
  }

  return (
    <PhoneTabPanel index={1} value={value}>
      <Box sx={style.container}>
        <NavigationButton
          showExternalContacts={showExternalContacts}
          showInternalContacts={showInternalContacts}
          currentlyShowing={currentlyShowing}
        />
        <Typography fontWeight={600} variant="caption" color={'text.primary'}>
          {panelTitle}
        </Typography>
        {contacts}
      </Box>
    </PhoneTabPanel>
  );
};

export default ContactsPanel;
