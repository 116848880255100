// Components/ui
import CallEndOutlinedIcon from '@mui/icons-material/CallEndOutlined';
import KeyboardVoiceOutlinedIcon from '@mui/icons-material/KeyboardVoiceOutlined';
import { Box, IconButton } from '@mui/material';
import { style } from './style';

type CallingActionButtonsProps = {
  rejectCall: () => void;
};
const CallingActionButtons = ({ rejectCall }: CallingActionButtonsProps) => {
  return (
    <Box sx={style.container}>
      <IconButton sx={style.hangUpButton} onClick={rejectCall} color="error">
        <CallEndOutlinedIcon />
      </IconButton>
      <IconButton sx={style.muteButton}>
        <KeyboardVoiceOutlinedIcon />
      </IconButton>
    </Box>
  );
};

export default CallingActionButtons;
