import { createAsyncThunk } from '@reduxjs/toolkit';
import { setSession } from '../features/sessionSlice/sessionSlice';
import {
  CognitoIdToken,
  CognitoRefreshToken,
  CognitoAccessToken,
} from 'amazon-cognito-identity-js';
// Types
import InitRequestDataReturn from '../types/InitRequestDataReturn';
import { RootState } from '../rootReducer';

interface CognitoUserSession {
  idToken?: CognitoIdToken;
  refreshToken?: CognitoRefreshToken;
  accessToken?: CognitoAccessToken;
  clockDrift?: number;
}

const initRequestData = createAsyncThunk<
  InitRequestDataReturn,
  void,
  { state: RootState }
>('session/initRequest', async (_, { dispatch, getState }) => {
  const { Space, Session } = getState();
  let jwtToken: string;

  if (Session.session && Session.session.isValid()) {
    jwtToken = Session.session.idToken.getJwtToken();
  } else {
    const sessionRefresh: CognitoUserSession = (await dispatch(setSession()))
      .payload;

    jwtToken = sessionRefresh.idToken.getJwtToken();
  }

  const URL_USER = Space.URL.USER;
  const URL_USERS = Space.URL.USERS;
  const URL_CONTACTS = Space.URL.CONTACTS;
  const URL_CONVERSATIONS = Space.URL.CONVERSATIONS;
  const URL_CALLS = Space.URL.CALLS;

  return {
    jwtToken,
    URL_USER,
    URL_USERS,
    URL_CONVERSATIONS,
    URL_CONTACTS,
    URL_CALLS,
  };
});

export default initRequestData;
