// Components/ui
import { Box, Typography } from '@mui/material';
// Types
import { CallInfo } from '../../../../../../../../../../../../context/Sip/hooks/useCallNotification/useCallNotification.type';

type CallDataDisplayProps = {
  data: CallInfo;
};

const CallDataDisplay = ({ data }: CallDataDisplayProps) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
        alignItems: 'center',
      }}
    >
      <Typography color={'text.primary'} variant="h6" fontWeight={'bold'}>
        {data.name && data.name !== '' ? data.name : data.number}
      </Typography>
      {data.name !== data.number && (
        <Typography color={'text.secondary'} variant="caption">
          {data.number}
        </Typography>
      )}
    </Box>
  );
};

export default CallDataDisplay;
