export const style = {
  container: {
    display: 'flex',
    alignItems: 'center',
    padding: '1.15rem 1rem 1rem 1rem',
    width: '260px',
    borderRadius: '10px',
    backgroundColor: 'background.default',
    border: '1px solid #E0E0E0',
    boxShadow: '0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1)',
  },
  avatar: {
    marginRight: '0.8rem',
    width: '60px',
    height: '60px',
  },
};
