// Translation
import { useTranslation } from 'react-i18next';
// Components/ui
import { Box, Typography } from '@mui/material';
// Icons
import { FiberManualRecord } from '@mui/icons-material';
// Types
import SocketConnectionStatus from '../../../../context/WebSocketProvider/types/SocketConnectionStatus';

interface Props {
  connectionStatus: SocketConnectionStatus;
}

const UserStatus = ({ connectionStatus }: Props) => {
  const { t } = useTranslation();

  return (
    <Box display={'flex'} alignItems={'center'}>
      <FiberManualRecord
        sx={{ fontSize: '0.75rem' }}
        color={connectionStatus === 'online' ? 'success' : 'error'}
      />
      <Typography
        variant="caption"
        sx={{ lineHeight: '1px', cursor: 'default', userSelect: 'none', color: "text.primary", marginLeft: '2.5px' }}
      >
        {connectionStatus === 'online' ? t('status.online') : t('status.offline')}
      </Typography>
    </Box>
  );
};

export default UserStatus;
