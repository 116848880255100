import { useTranslation } from 'react-i18next';
import { useEffect, useContext, useState } from 'react';
// Context
import SipContext from '../../../../context/Sip/SipContext';
// Redux
import { fetchUsers } from '../../../../redux/features/usersSlice/usersSlice';
import { useAppDispatch } from '../../../../hooks/useAppDispatch';
// Components/ui
import { OpenDrawerButton, Drawer, CallNotification } from './components';
import { Box } from '@mui/material';
import { style } from './style';
import { fetchLogs } from '../../../../redux/features/callSlice/callSlice';
import { selectUser } from '../../../../redux/features/userSlice/userSlice';
import { useSelector } from 'react-redux';

const Calls = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const user = useSelector(selectUser);

  const {
    userAgent: { session, callStatus },
    drawer: {
      drawerOpen,
      handleCloseDrawer,
      handleOpenDrawer,
      handleDrawerTabChange,
      tabValue,
    },
  } = useContext(SipContext);

  window.onbeforeunload = function () {
    if (session !== null) {
      return t('calls.leaveWarning');
    }
  };

  useEffect(() => {
    const fetchLogsData = {
      limit: 50,
      pos: '',
    };

    dispatch(fetchLogs(fetchLogsData));
  }, []);

  if (!user.sipExtensionConfig) return null;

  const initialIsPinned = localStorage.getItem('callPopupIsPinned') === 'true';
  const [callPopupIsPinned, setCallPopupIsPinned] = useState<boolean>(
    initialIsPinned !== null ? initialIsPinned : true
  );

  useEffect(() => {
    callPopupIsPinned && handleOpenDrawer();
  }, [callPopupIsPinned]);

  return (
    <>
      {drawerOpen ? (
        <Drawer
          open={drawerOpen}
          onClose={handleCloseDrawer}
          handleDrawerTabChange={handleDrawerTabChange}
          tabValue={tabValue}
        />
      ) : (
        <Box sx={style.container}>
          <CallNotification />
          <OpenDrawerButton onClick={handleOpenDrawer} />
        </Box>
      )}
    </>
  );
};

export default Calls;
