export const style = {
  container: {
    height: '650px',
    backgroundColor: 'background.default',
    width: '111%',
    paddingX: '13px',
    borderTopLeftRadius: '10px',
    borderTopRightRadius: '10px',
    boxShadow:
      '0px -4px 6px -1px rgba(0, 0, 0, 0.1), -2px 0 4px -2px rgba(0, 0, 0, 0.2), 2px 0 4px -2px rgba(0, 0, 0, 0.2)',
    borderTop: '1px solid #e5e7eb',
    borderLeft: '1px solid #e5e7eb',
    borderRight: '1px solid #e5e7eb',
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
  },
};
