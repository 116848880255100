import { useEffect, useRef } from 'react';
// Types
import { UseCallSound } from './useCallSound.type';

const useCallSound = (): UseCallSound => {
  const ringRef = useRef<HTMLAudioElement | null>(null);
  const outgoingRef = useRef<HTMLAudioElement | null>(null);

  function playRing() {
    if (ringRef.current) {
      ringRef.current.play().catch((error) => {
        console.log('ring playback failed');
      });
    }
  }

  function stopRing() {
    if (ringRef.current) {
      ringRef.current.pause();
      ringRef.current.currentTime = 0;
    }
  }

  function playOutgoing() {
    if (outgoingRef.current) {
      outgoingRef.current.play().catch((error) => {
        console.log('outgoing playback failed');
      });
    }
  }

  function stopOutgoing() {
    if (outgoingRef.current) {
      outgoingRef.current.pause();
      outgoingRef.current.currentTime = 0;
    }
  }

  useEffect(() => {
    ringRef.current = document.getElementById('telephone-ring') as HTMLAudioElement;
    outgoingRef.current = document.getElementById(
      'telephone-outgoing'
    ) as HTMLAudioElement;
  }, []);

  return {
    playRing,
    stopRing,
    playOutgoing,
    stopOutgoing,
  };
};

export default useCallSound;
