export const style = {
  container: {
    display: 'flex',
    width: '100%',
    height: '100%',
    flexDirection: 'column',
    position: 'relative',
    marginBottom: '10px',
  },
  loader: {
    position: 'absolute',
    top: '2rem',
    width: '100%',
    borderRadius: 1,
  },
};
