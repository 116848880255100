export const style = {
  container: {
    display: 'flex',
    gap: '10px',
    alignItems: 'center',
    marginBottom: '10px',
  },
  callInfoContainer: { display: 'flex', alignItems: 'center' },
  callInfoIcon: { height: '13px', widht: '13px' },
};
