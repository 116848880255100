import { useState } from 'react';
// Components/ui
import { style } from './style';
import { IconButton, Box } from '@mui/material';
import CallEndOutlinedIcon from '@mui/icons-material/CallEndOutlined';
import KeyboardVoiceOutlinedIcon from '@mui/icons-material/KeyboardVoiceOutlined';
import MicOffOutlinedIcon from '@mui/icons-material/MicOffOutlined';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import PhonePausedOutlinedIcon from '@mui/icons-material/PhonePausedOutlined';
import CallOutlinedIcon from '@mui/icons-material/CallOutlined';

type AnsweredActionButtonsProps = {
  endCall: () => void;
  handleOpenTransferCallWindow: () => void;
  holdStatus: boolean;
  hold: () => void;
  unhold: () => void;
  muteStatus: boolean;
  mute: () => void;
  unmute: () => void;
};
const AnsweredActionButtons = ({
  endCall,
  handleOpenTransferCallWindow,
  holdStatus,
  hold,
  unhold,
  muteStatus,
  mute,
  unmute,
}: AnsweredActionButtonsProps) => {
  return (
    <Box sx={style.container}>
      <IconButton onClick={handleOpenTransferCallWindow} sx={style.secondaryButton}>
        <SwapHorizIcon />
      </IconButton>
      <IconButton sx={style.hangUpButton} onClick={endCall} color="error">
        <CallEndOutlinedIcon />
      </IconButton>
      <IconButton onClick={muteStatus ? unmute : mute} sx={style.secondaryButton}>
        {muteStatus ? <MicOffOutlinedIcon /> : <KeyboardVoiceOutlinedIcon />}
      </IconButton>
      <IconButton onClick={holdStatus ? unhold : hold} sx={style.secondaryButton}>
        {holdStatus ? <PhonePausedOutlinedIcon /> : <CallOutlinedIcon />}
      </IconButton>
    </Box>
  );
};

export default AnsweredActionButtons;
