// Components/ui
import { IconButton, Box } from '@mui/material';
import { style } from './style';
import CallEndOutlinedIcon from '@mui/icons-material/CallEndOutlined';
import PhoneIcon from '@mui/icons-material/Phone';

type RingingActionButtonsProps = {
  acceptCall: () => void;
  rejectCall: () => void;
};
const RingingActionButtons = ({
  acceptCall,
  rejectCall,
}: RingingActionButtonsProps) => {
  return (
    <Box sx={style.container}>
      <IconButton sx={style.answerButton} onClick={acceptCall} color="success">
        <PhoneIcon />
      </IconButton>
      <IconButton sx={style.rejectButton} onClick={rejectCall} color="error">
        <CallEndOutlinedIcon />
      </IconButton>
    </Box>
  );
};

export default RingingActionButtons;
