import { useEffect } from 'react';
// Context
import SipContext from './SipContext';
// Redux
import { selectUser } from '../../redux/features/userSlice/userSlice';
import { useSelector } from 'react-redux';
// Types
import { SipContextType } from './SipContext';
import { CallStatus } from './hooks/useUserAgent/types/CallStatus';
// Hooks
import useTimer from './hooks/useTimer/useTimer';
import useUserAgent from './hooks/useUserAgent/useUserAgent';
import useDial from './hooks/useDial/useDial';
import useCallWindow from './hooks/useCallWindow/useCallWindow';
import useTransfer from './hooks/useTransfer/useTransfer';
import useCallNotification from './hooks/useCallNotification/useCallNotification';
import useDrawer from './hooks/useDrawer/useDrawer';
import useCallSound from './hooks/useCallRing/useCallSound';

const SipContextProvider = ({ children }) => {
  const user = useSelector(selectUser);

  const { extension, password, sipDomain } = user?.sipExtensionConfig || {};

  const userAgent = useUserAgent(extension, password, sipDomain);
  // const transferUserAgent = useUserAgent(extension, password);
  // const transferUserAgent = useUserAgent('', '', '');
  const callTimer = useTimer();
  const dial = useDial();
  const transfer = useTransfer();
  const callWindow = useCallWindow();
  const notification = useCallNotification();
  const drawer = useDrawer();
  const callSound = useCallSound();

  function handleDialPress(value: string) {
    dial.handleDial(value);
    if (userAgent.callStatus === CallStatus.Answered) {
      userAgent.sendDTMF(value);
    }
  }

  function handleMakeCall() {
    if (!dial.dialValue) return;

    userAgent?.makeCall(dial.dialValue);
  }

  function handleMakeCallFromExternal(number: string) {
    dial.setValue(number);
    drawer.handleDrawerTabChange(null, 0);
    userAgent?.makeCall(number);
  }

  function handleMakeVoiceMailCall() {
    dial.setValue('*98');
    drawer.handleDrawerTabChange(null, 0);
    userAgent?.makeCall('*98');
  }

  function handleAnswerNotificationCall() {
    notification.handleHide();
    userAgent.acceptCall();
    drawer.handleDrawerTabChange(null, 0);
    drawer.handleOpenDrawer();
  }

  function handleRejectNotificationCall() {
    notification.handleHide();
    userAgent.endCall();
  }

  function handleDirectTransfer() {
    userAgent.blindTransferCall(transfer.callSelector.value);
  }

  function handleCompleteAttendedTransfer() {
    userAgent.completeAttendedTransfer();
  }

  function handleHangUpTransferCall() {
    userAgent.endTransferCall();
    userAgent.unhold();
    userAgent.setupRemoteMedia(userAgent.session);
    callWindow.handleCloseTransferCallWindow();
    transfer.reset();
  }

  function handleMakeTransferCall() {
    userAgent.hold();
    userAgent.attendedTransferCall(transfer.callSelector.value);
    transfer.setSupervisedTransferWindow();
  }

  // transferUserAgent UEH
  useEffect(() => {
    switch (userAgent.transferCallStatus) {
      case CallStatus.Idle:
        if (userAgent.session) {
          userAgent.unhold();
          userAgent.setupRemoteMedia(userAgent.session);
          callWindow.handleCloseTransferCallWindow();
          transfer.reset();
        }
        break;
      default:
        break;
    }
  }, [userAgent.transferCallStatus]);

  // userAgent UEH
  useEffect(() => {
    switch (userAgent?.callStatus) {
      case CallStatus.Idle:
        notification.handleHide();
        callSound.stopRing();
        callSound.stopOutgoing();
        dial.reset();
        callTimer.reset();
        transfer.reset();

        break;
      case CallStatus.Ringing:
        const { _displayName, uri } = userAgent.session.request.from;

        notification.setCallInfo({
          name: _displayName,
          number: uri.normal.user,
        });
        callSound.playRing();

        if (drawer.drawerOpen && drawer.tabValue !== 0) {
          drawer.handleDrawerTabChange(null, 0);
        }

        notification.handleShow();
        break;
      case CallStatus.Answered:
        callSound.stopRing();
        callSound.stopOutgoing();
        notification.handleHide();
        callTimer.start();
        dial.reset();

        break;
      case CallStatus.Calling:
        callSound.playOutgoing();
        break;
      default:
        break;
    }
  }, [userAgent.callStatus]);

  const value: SipContextType = {
    callTimer,
    dial,
    userAgent,
    // transferUserAgent,
    handleMakeCall,
    handleDirectTransfer,
    callWindow,
    transfer,
    notification,
    drawer,
    handleAnswerNotificationCall,
    handleRejectNotificationCall,
    handleMakeVoiceMailCall,
    handleMakeCallFromExternal,
    handleMakeTransferCall,
    handleHangUpTransferCall,
    handleCompleteAttendedTransfer,
    handleDialPress,
  };

  return <SipContext.Provider value={value}>{children}</SipContext.Provider>;
};

export default SipContextProvider;
