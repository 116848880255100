// Types
import { Pagination } from '../../../../../../../../../../redux/features/contactsSlice/types/Pagination';
import { Filter } from '../../../../../../../../../../redux/features/contactsSlice/types/Filter';
function getPaginationData(): Pagination {
  return {
    currentPage: 1,
    perPage: 20,
    order: 'ASC',
    orderColumn: 'name',
    format: 'IContact',
    operator: 'OR',
    filter: [],
  };
}

function generateFilterItem(searchValue: string, column: string): Filter {
  return {
    column,
    value: searchValue,
    condition: '',
  };
}

export default {
  getPaginationData,
  generateFilterItem,
};
