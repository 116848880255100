// Components/ui
import { Box, TextField } from '@mui/material';
import { style } from './style';
import { useEffect, useRef } from 'react';

type DialVisorProps = {
  value: string;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
};
const DialVisor = ({ value, handleChange }: DialVisorProps) => {
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    inputRef.current?.focus();
  }, []);

  return (
    <Box sx={style.container}>
      <TextField
        size="small"
        id="dial-visor"
        sx={{
          '& .MuiInputBase-input': {
            textAlign: 'center',
            fontSize: '1.5rem',
            fontWeight: 500,
            letterSpacing: '0.1rem',
          },
        }}
        value={value}
        onChange={handleChange}
        inputRef={inputRef}
      />
    </Box>
  );
};

export default DialVisor;
