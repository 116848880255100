import { useEffect, useState } from 'react';
// Redux
import { useAppDispatch } from '../../../../../../../../../../../../hooks/useAppDispatch';
import { useSelector } from 'react-redux';
import {
  selectPagination,
  selectContacts,
} from '../../../../../../../../../../../../redux/features/contactsSlice/selectors';
// Utils
import helper from '../../helper';
// Types
import { UseSearchContacts } from './useSearchContacts.type';
import { IContact } from '@trii/types/dist/Contacts';
import {
  fetchContacts,
  resetContacts,
} from '../../../../../../../../../../../../redux/features/contactsSlice/contactsSlice';
import { Pagination } from '../../../../../../../../../../../../redux/features/contactsSlice/types/Pagination';

const useSearchContacts = (): UseSearchContacts => {
  const dispatch = useAppDispatch();
  const contacts = useSelector(selectContacts);
  const pagination = useSelector(selectPagination);
  const [search, setSearch] = useState<string>('');
  const [contactList, setContactList] = useState<IContact[]>([]);
  const [allLoaded, setAllLoaded] = useState<boolean>(false);
  const [isNextPage, setIsNextPage] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);
  const [timer, setTimer] = useState<NodeJS.Timeout>(null);
  const [currentPage, setCurrentPage] = useState<number>(1);

  const searchNewContact = async (data: Pagination) => {
    dispatch(resetContacts());
    await dispatch(fetchContacts(data));
  };

  const handleChange = async (value: string) => {
    setSearch(value);
    setIsNextPage(false);
    setAllLoaded(false);
    setCurrentPage(1);
    setContactList([]);
    clearTimeout(timer);
    if (value) {
      setIsLoading(true);
      setTimer(
        setTimeout(async () => {
          const newData = helper.getPaginationData();

          newData.filter = [
            helper.generateFilterItem(value, 'name'),
            helper.generateFilterItem(value, 'phone'),
          ];

          await searchNewContact(newData);
          setIsLoading(false);
        }, 1000)
      );
    } else {
      setIsLoading(false);
    }
  };

  const handleScroll = async (e: React.UIEvent<HTMLElement>) => {
    const element = e.target as HTMLElement;
    const bottom = element.scrollHeight - element.scrollTop === element.clientHeight;
    if (bottom && !allLoaded) {
      const newPage = currentPage + 1;
      const newData = helper.getPaginationData();

      newData.filter = [
        helper.generateFilterItem(search, 'name'),
        helper.generateFilterItem(search, 'phone'),
      ];
      newData.currentPage = newPage;

      setIsNextPage(true);
      setCurrentPage(newPage);
      searchNewContact(newData);
    }
  };

  useEffect(() => {
    if (contacts && contacts.length > 0) {
      if (isNextPage) {
        if (pagination.total === contactList.length) {
          setAllLoaded(true);
          return;
        }
        setContactList([...contactList, ...contacts]);
      } else {
        setContactList(contacts);
      }
    }
  }, [contacts]);

  // When the search is empty, the component will fetch the first page
  useEffect(() => {
    if (search === '') {
      const fetchData = helper.getPaginationData();
      searchNewContact(fetchData);
    }
  }, [search]);

  return {
    handleScroll,
    handleChange,
    contactList,
    isLoading,
    search,
  };
};

export default useSearchContacts;
