// Components/ui
import { Avatar, Typography } from '@mui/material';

interface Props {
  userName: string;
}

const UserInfo = ({ userName }: Props) => {
  return (
    <div
      style={{
        display: 'flex',
        gap: 9,
        justifyItems: 'center',
        alignItems: 'center',
      }}
    >
      <Avatar sx={{ width: 26, height: 26 }} />
      <Typography variant="body2" color="text.primary">
        {userName}
      </Typography>
    </div>
  );
};

export default UserInfo;
