import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
// Utils
import initRequestData from '../../functions/initRequestData';
import service from './service';
// Types
import { CallSlice } from './types/CallSlice';
import InitRequestDataReturn from '../../types/InitRequestDataReturn';
import { FetchLogData } from './types/FetchLogData';
import { RootState } from '../../rootReducer';

const initialState: CallSlice = {
  logs: [],
  status: {
    fetch: 'idle',
    fetchExtra: 'idle',
  },
};

export const fetchLogs = createAsyncThunk(
  'call/fetchLogs',
  async (fetchData: FetchLogData, { dispatch }) => {
    const { jwtToken, URL_CALLS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    const logs = await service.fetchLogs(jwtToken, URL_CALLS, fetchData);

    return logs;
  }
);

export const fetchExtraLogs = createAsyncThunk(
  'call/fetchExtraLogs',
  async (_, { dispatch, getState }) => {
    const { jwtToken, URL_CALLS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    const state = getState() as RootState;
    const currentLogs = state.Calls.logs;
    const lastLogId = currentLogs[currentLogs.length - 1].id;
    const fetchData: FetchLogData = {
      limit: 2,
      pos: lastLogId,
    };

    const logs = await service.fetchLogs(jwtToken, URL_CALLS, fetchData);
    return logs;
  }
);

const callSlice = createSlice({
  name: 'call',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchLogs.pending, (state) => {
      state.status.fetch = 'loading';
    });
    builder.addCase(fetchLogs.fulfilled, (state, action) => {
      state.status.fetch = 'succeeded';
      console.log('Succeeded fetchLogs:', action.payload);
      state.logs = action.payload;
    });
    builder.addCase(fetchLogs.rejected, (state, err) => {
      state.status.fetch = 'rejected';
      console.error('Rejected fetchLogs', err);
    });
    builder.addCase(fetchExtraLogs.pending, (state) => {
      state.status.fetchExtra = 'loading';
    });
    builder.addCase(fetchExtraLogs.fulfilled, (state, action) => {
      state.status.fetchExtra = 'succeeded';
      console.log('Succeeded fetchExtraLogs:', action.payload);
      state.logs.push(...action.payload);
    });
  },
});

export default callSlice.reducer;
