import { useEffect, useState } from 'react';
// Components/ui
import { Header, Body, Footer } from './components';
import { Box, Fade, Grow, Theme } from '@mui/material';
// Types
import { ChatType, IConversation } from '@trii/types/dist/Conversations';
// Redux
import { useSelector } from 'react-redux';
import { selectedTheme } from '../../../../../../redux/features/themeSlice/themeSlice';
import { removeOpenChatId } from '../../../../../../redux/features/chatsSlice/chatsSlice';
import { useAppDispatch } from '../../../../../../hooks/useAppDispatch';

interface Props {
  data: IConversation;
}

const Chat = ({ data }: Props) => {
  const [open, setOpen] = useState(false);

  const onSelectedTheme = useSelector(selectedTheme);

  useEffect(() => {
    setOpen(true);
  }, []);

  return (
    <div>
      <Grow
        in={open}
        unmountOnExit
        style={{ transformOrigin: '0 0 0' }}
        {...(open ? { timeout: 1000 } : {})}
      >
        <Box
          sx={{
            width: '270px',
            //@ts-ignore
            border: `1px solid ${onSelectedTheme.palette.divider}`,
            //@ts-ignore
            backgroundColor: onSelectedTheme.palette.background.default,
            borderRadius: '4px',
            boxShadow: '0 -1px 6px 0 rgba(0,0,0,.25)',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Header
            chatId={data.id}
            userName={
              data.type === ChatType.EXTERNAL ? data.channelInfo.name : data.chatName
            }
            chatType={data.type}
          />
          <Body messages={[]} />
          <Footer />
        </Box>
      </Grow>
    </div>
  );
};

export default Chat;
