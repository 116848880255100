import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  CookieStorage,
  CognitoUser,
  CognitoUserSession,
} from 'amazon-cognito-identity-js';
import { RootState } from '../../rootReducer';
// Helper
import sessionHelper from './utils/sessionHelper';
// Types
import { SessionState } from './types/SessionState';
import { ExpirationState } from './types/ExpirationState';

const initialState: SessionState = {
  session: null,
  status: {
    setSession: 'idle',
  },
  expiration: {
    state: 'valid',
    time: 30,
  },
};

export const setSession = createAsyncThunk<
  CognitoUserSession,
  void,
  { state: RootState }
>('session/setSession', async (_, { getState }) => {
  return new Promise((resolve, reject) => {
    const { pool } = getState().Pool;
    const user = pool.getCurrentUser();

    if (user) {
      user.getSession((err, session: CognitoUserSession) => {
        if (err) {
          reject(err);
        } else {
          resolve(sessionHelper.checkSessionValidity(user, session));
        }
      });
    } else {
      reject(false);
    }
  });
});

export const logout = createAsyncThunk<void, void, { state: RootState }>(
  'session/logout',
  async (_, { getState }) => {
    const { pool } = getState().Pool;
    const user = pool.getCurrentUser();

    if (user) {
      user.signOut();

      window.location.href = `${
        window.location.protocol
      }//${window.location.host.replace('account.', '')}/signin`;
    }
  }
);

const sessionSlice = createSlice({
  name: 'session',
  initialState,
  reducers: {
    setExpirationState: (state, action: { payload: ExpirationState }) => {
      state.expiration.state = action.payload;
    },
    setExpirationTime: (state, action: { payload: number }) => {
      state.expiration.time = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(setSession.pending, (state) => {
        state.status.setSession = 'loading';
      })
      .addCase(setSession.fulfilled, (state, action) => {
        const session = action.payload;
        console.log('success fetching session: ', session);

        state.status.setSession = 'succeeded';
        state.session = session;
      })
      .addCase(setSession.rejected, (state, action) => {
        console.log('error fetching session: ', action.error);
        state.status.setSession = 'rejected';
        const encodedUrlPath = encodeURIComponent(window.location.pathname);

        window.location.href = `${
          window.location.protocol
        }//${window.location.host.replace(
          'account.',
          ''
        )}/signin?redirect=${encodedUrlPath}`;
      });
  },
});

export const setUser = (Username, Pool) => {
  return new CognitoUser({
    Username,
    Pool,
    Storage: new CookieStorage({
      secure: false,
      domain: window.location.host.replace(':3000', '').replace(':3001', ''),
    }),
  });
};

export const { setExpirationState, setExpirationTime } = sessionSlice.actions;

// Selectors
export const selectSession = (state) => state.Session.session;
export const selectExpirationState = (state) => state.Session.expiration.state;
export const selectExpirationTime = (state) => state.Session.expiration.time;
export const getSessionStatus = (state) => state.Session.status.setSession;
export const getSessionError = (state) => state.Session.error.setSession;

export default sessionSlice.reducer;
