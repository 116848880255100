// Components/ui
import { Box, Typography } from '@mui/material';

type CallInfoProps = {
  number: string;
  name: string;
};
const CallInfo = ({ number, name }: CallInfoProps) => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Typography
        sx={{
          userSelect: 'none',
        }}
        color={'text.primary'}
        variant="subtitle2"
      >
        {name || number}
      </Typography>
      <Typography
        sx={{
          userSelect: 'none',
        }}
        color={'text.secondary'}
        variant="caption"
      >
        {number || ''}
      </Typography>
    </Box>
  );
};

export default CallInfo;
