// Components/ui
import { Box, IconButton } from '@mui/material';
import { style } from './style';
import CallIcon from '@mui/icons-material/Call';
import CallEndIcon from '@mui/icons-material/CallEnd';

type ActionButtonsProps = {
  handleAnswer: () => void;
  handleReject: () => void;
};
const ActionButtons = ({ handleAnswer, handleReject }: ActionButtonsProps) => {
  return (
    <Box sx={style.container}>
      <IconButton
        onClick={handleAnswer}
        size="small"
        color="success"
        sx={style.button}
      >
        <CallIcon sx={style.icon} />
      </IconButton>
      <IconButton
        onClick={handleReject}
        size="small"
        color="error"
        sx={style.button}
      >
        <CallEndIcon sx={style.icon} />
      </IconButton>
    </Box>
  );
};

export default ActionButtons;
