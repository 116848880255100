export const style = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  callState: {
    fontWeight: 600,
  },
  callNumber: {
    fontSize: '1.5rem',
    fontWeight: 600,
    letterSpacing: '0.1rem',
  },
  timer: {
    fontSize: '1rem',
    fontWeight: 400,
    letterSpacing: '0.1rem',
    marginTop: '1.25rem',
    marginBottom: '0.5rem',
  },
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '1rem',
  },
};
