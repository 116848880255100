import { useState, useEffect, useCallback } from 'react';
// Types
import { UseTimer } from './useTimer.type';

const useTimer = (initialState: number = 0): UseTimer => {
  const [seconds, setSeconds] = useState<number>(initialState);
  const [intervalId, setIntervalId] = useState<NodeJS.Timeout | null>(null);

  const formatTime = (seconds: number): string => {
    const minutes = Math.floor(seconds / 60);
    seconds %= 60;
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  };

  const start = useCallback(() => {
    if (intervalId !== null) return;
    const id = setInterval(() => {
      setSeconds((seconds) => seconds + 1);
    }, 1000);
    setIntervalId(id);
  }, [intervalId]);

  const reset = useCallback(() => {
    if (intervalId !== null) {
      clearInterval(intervalId);
      setIntervalId(null);
    }
    setSeconds(0);
  }, [intervalId]);

  useEffect(() => {
    return () => {
      if (intervalId !== null) {
        clearInterval(intervalId);
      }
    };
  }, [intervalId]);

  return { time: formatTime(seconds), start, reset };
};

export default useTimer;
