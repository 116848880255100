import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
// Types
import { ChatsState } from './types';
import InitRequestDataReturn from '../../types/InitRequestDataReturn';
import { RootState } from '../../rootReducer';
// Utils
import chatsSliceService from './service';
import initRequestData from '../../functions/initRequestData';

const initialState: ChatsState = {
  chats: [],
  openChatIds: [],
};

export const fetchChats = createAsyncThunk(
  'chats/fetchChats',
  async (_, { getState, dispatch }) => {
    const state = getState() as RootState;
    const { jwtToken, URL_CONVERSATIONS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    const { user } = state.User;

    const response = await chatsSliceService.fetchFloatingWindowChats(
      URL_CONVERSATIONS,
      jwtToken,
      user.uid
    );

    return response;
  }
);

export const deleteFloatingWindow = createAsyncThunk(
  'chats/deleteFloatingWindow',
  async (
    data: { chatId: string; conversationId: string },
    { getState, dispatch }
  ) => {
    const state = getState() as RootState;
    const { jwtToken, URL_CONVERSATIONS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    const { user } = state.User;

    await chatsSliceService.deleteFloatingWindow(
      URL_CONVERSATIONS,
      jwtToken,
      user.uid,
      data
    );
  }
);

const chatsSlice = createSlice({
  name: 'chats',
  initialState,
  reducers: {
    addOpenChatId: (state, action: PayloadAction<string>) => {
      state.openChatIds.push(action.payload);
    },
    removeOpenChatId: (state, action: PayloadAction<string>) => {
      state.openChatIds = state.openChatIds.filter((id) => id !== action.payload);
    },
    removeChatById: (state, action: PayloadAction<string>) => {
      state.chats = state.chats.filter((chat) => chat.id !== action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchChats.fulfilled, (state, action) => {
      console.log('Succeeded fetchChats:', action.payload);
      state.chats = action.payload;
    });
  },
});

export const { addOpenChatId, removeOpenChatId, removeChatById } =
  chatsSlice.actions;

export default chatsSlice.reducer;
