export const style = {
  container: {
    display: 'flex',
    gap: '1rem',
    position: 'absolute',
    bottom: '3rem',
    right: '0rem',
    zIndex: '100',
    alignItems: 'center',
  },
};
