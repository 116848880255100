// Components/ui
import { PhoneTabPanel } from '../../layout';
import { CallItem } from './components';
// Redux
import { useSelector } from 'react-redux';
import {
  selectCallLogs,
  selectFetchExtraLogsStatus,
} from '../../../../../../../../redux/features/callSlice/selectors';
import { useAppDispatch } from '../../../../../../../../hooks/useAppDispatch';
import { useEffect } from 'react';
import {
  fetchExtraLogs,
  fetchLogs,
} from '../../../../../../../../redux/features/callSlice/callSlice';
import { Box, LinearProgress } from '@mui/material';

type CallsHistoryPanelProps = {
  value: number;
};

const CallsHistoryPanel = ({ value }: CallsHistoryPanelProps) => {
  const dispatch = useAppDispatch();
  const logs = useSelector(selectCallLogs);
  const fetchExtraLogsStatus = useSelector(selectFetchExtraLogsStatus);

  async function fetchMoreLogs() {
    await dispatch(fetchExtraLogs());
  }

  return (
    <PhoneTabPanel onScrollEnd={fetchMoreLogs} value={value} index={2}>
      {logs?.map((log, index) => (
        <CallItem key={index} data={log} />
      ))}
      <Box sx={{ width: '100%', p: 1 }}>
        {fetchExtraLogsStatus === 'loading' && <LinearProgress />}
      </Box>
    </PhoneTabPanel>
  );
};

export default CallsHistoryPanel;
