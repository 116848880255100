// Provider
import SocketProvider from './context/WebSocketProvider/SocketProvider';
import SipContextProvider from './context/Sip/SipContextProvider';
// Components/ui
import { Footer } from './components';
import { ThemeProvider, createTheme } from '@mui/material';
import { useSelector } from 'react-redux';
import { selectedTheme } from './redux/features/themeSlice/themeSlice';
import { useMemo } from 'react';
import RootProps from './types/RootProps';
import ModuleIconsProvider from './context/ModuleIcons/ModuleIconsProvider';
interface Props {
  rootProps: RootProps;
}
const App = ({ rootProps }: Props) => {
  const themeRedux = useSelector(selectedTheme);
  const theme = useMemo(() => createTheme(themeRedux), [themeRedux]);

  return (
    <SocketProvider>
      <SipContextProvider>
        <ModuleIconsProvider>
          <ThemeProvider theme={theme}>
            <Footer rootProps={rootProps} />
          </ThemeProvider>
        </ModuleIconsProvider>
      </SipContextProvider>
    </SocketProvider>
  );
};

export default App;
