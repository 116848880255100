// Utils
import getRequestConfig from '../../functions/getRequestConfig';
import axios from 'axios';
// Types
import { ICallLog } from '@trii/types/dist/Calls';
import { FetchLogData } from './types/FetchLogData';
async function fetchLogs(
  jwtToken: string,
  URL_CALLS: string,
  fetchData: FetchLogData
): Promise<ICallLog[]> {
  const config = getRequestConfig.basic(jwtToken);
  const { limit, pos } = fetchData;

  const response = await axios.get<ICallLog[]>(
    `${URL_CALLS}/CallLogs?start=0&end=0&type=all&limit=${limit}&pos=${pos}`,
    config
  );

  return response.data;
}

export default {
  fetchLogs,
};
