// Context
import { useContext, useEffect, useState } from 'react';
import SipContext from '../../../../../../../../context/Sip/SipContext';
// Components/ui
import { PhoneTabPanel } from '../../layout';
import { Call, Dial } from './components';
import { CallStatus } from '../../../../../../../../context/Sip/hooks/useUserAgent/types/CallStatus';

type CallPanelProps = {
  value: number;
};
const CallPanel = ({ value }: CallPanelProps) => {
  const [show, setShow] = useState(false);
  const {
    userAgent: { callStatus },
  } = useContext(SipContext);

  useEffect(() => {
    setTimeout(() => {
      setShow(true);
    }, 150);
  }, []);

  if (!show) return null;

  return (
    <PhoneTabPanel value={value} index={0}>
      <div style={{ paddingTop: '1.5rem' }}>
        {callStatus === CallStatus.Idle ? <Dial /> : <Call />}
      </div>
    </PhoneTabPanel>
  );
};

export default CallPanel;
