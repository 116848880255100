import React, { createContext, useEffect, useRef, useState } from 'react';
// Redux
import { useDispatch, useSelector } from 'react-redux';
import initRequestData from '../../redux/functions/initRequestData';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { RootState } from '../../redux/store';
// Socket.IO
import { io, Socket } from 'socket.io-client';
// Types
import SocketContextType from './types/SocketContextType';
import SocketConnectionStatus from './types/SocketConnectionStatus';
import InitRequestDataReturn from '../../redux/types/InitRequestDataReturn';
import {
  selectExpirationState,
  setExpirationState,
} from '../../redux/features/sessionSlice/sessionSlice';

export const socketContext = createContext<SocketContextType>({
  socketConnectionState: null,
  connectionStatus: 'offline',
  subscribeEvent: () => {},
  unsubscribeEvent: () => {},
  listenToAllEvents: () => {},
  stopListeningToAllEvents: () => {},
  emitEvent: () => {},
  sessionMessageModalOpen: {
    open: false,
    message: '',
  },
  setSessionMessageModalOpen: () => {},
});

interface Props {
  children: React.ReactNode;
}

export const SocketProvider = ({ children }: Props) => {
  const dispatch: ThunkDispatch<RootState, void, AnyAction> = useDispatch();

  const expirationState = useSelector(selectExpirationState);

  const [sessionMessageModalOpen, setSessionMessageModalOpen] = useState({
    open: false,
    message: '',
  });
  const [token, setToken] = useState<string>('');
  const [connectionStatus, setConnectionStatus] =
    useState<SocketConnectionStatus>('offline');

  const [socketConnectionState, setSocketConnectionState] = useState<Socket | null>(
    null
  );

  const expirationStateRef = useRef(expirationState);
  const sessionMessageModalOpenMessageRef = useRef(sessionMessageModalOpen.message);
  const socketConnectionRef = useRef<Socket | null>(null);

  const setSocketConnection = (socket: Socket | null) => {
    socketConnectionRef.current = socket;
    setSocketConnectionState(socket);
  };

  // Token functions
  const getToken = async () => {
    const { jwtToken } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    return jwtToken;
  };

  // Event functions
  const subscribeEvent = (eventName: string, callback: (...args: any[]) => void) => {
    if (socketConnectionRef.current) {
      console.log('SocketConnection on Footer - Subscribing to event:', eventName);
      socketConnectionRef.current.on(eventName, callback);
    }
  };

  const unsubscribeEvent = (eventName: string) => {
    if (socketConnectionRef.current) {
      console.log(
        'SocketConnection on Footer - Unsubscribing from event:',
        eventName
      );
      socketConnectionRef.current.off(eventName);
    }
  };

  const emitEvent = (eventName: string, ...args: any[]) => {
    if (socketConnectionRef.current) {
      console.log('SocketConnection on Footer - Emitting event:', eventName);
      socketConnectionRef.current.emit(eventName, ...args);
    }
  };

  const listenToAllEvents = (
    callback: (eventName: string, ...args: any[]) => void
  ) => {
    if (socketConnectionRef.current) {
      socketConnectionRef.current.onAny(callback);
    }
  };

  const stopListeningToAllEvents = () => {
    if (socketConnectionRef.current) {
      console.log('SocketConnection on Footer - Stopped listening to all events');
      socketConnectionRef.current.offAny();
    }
  };

  useEffect(() => {
    expirationStateRef.current = expirationState;
  }, [expirationState]);

  useEffect(() => {
    sessionMessageModalOpenMessageRef.current = sessionMessageModalOpen.message;
  }, [sessionMessageModalOpen.message]);

  useEffect(() => {
    if (socketConnectionState) return;

    const space = localStorage.getItem('spaceInfo');
    const spaceId = space ? JSON.parse(space).id : null;

    if (!spaceId) return;

    let reconnectIntervalId = null;

    const connectSocket = async () => {
      // Refresh the token
      const newToken = await getToken();

      let socket: Socket;
      if (typeof window !== 'undefined' && (window as any).socketConnection) {
        console.log('Reusing existing socket connection');

        socket = (window as any).socketConnection;
        socket.auth = {
          token: newToken,
          spaceId: spaceId,
        };
        socket.connect();
      } else {
        console.log('Creating new socket connection');

        socket = io('https://ws.trii.app', {
          auth: {
            token: newToken,
            spaceId: spaceId,
          },
        });

        if (typeof window !== 'undefined') {
          (window as any).socketConnection = socket;
        }

        socket.on('connect', () => {
          console.log('Footer: Connected to Socket.IO server');
          setConnectionStatus('online');

          // Clear the interval when connected
          if (reconnectIntervalId) {
            clearInterval(reconnectIntervalId);
            reconnectIntervalId = null;
          }
        });

        socket.on('disconnect', () => {
          setConnectionStatus('offline');

          console.log('Footer: Disconnected from Socket.IO server');

          if (reconnectIntervalId) {
            clearInterval(reconnectIntervalId);
          }

          console.log(
            'socket.on(disconnect) - expirationStateRef.current : ',
            expirationStateRef.current
          );
          console.log(
            'socket.on(disconnect) - sessionMessageModalOpenMessageRef.current : ',
            sessionMessageModalOpenMessageRef.current
          );

          if (
            sessionMessageModalOpenMessageRef.current !==
              'modal.messageMaximumSessions' &&
            sessionMessageModalOpenMessageRef.current !==
              'modal.messageSessionExpired'
          ) {
            console.log('socket.on(disconnect) - Setting reconnectIntervalId');
            reconnectIntervalId = setInterval(connectSocket, 10000);
          }
        });

        socket.on('connect_error', (error) => {
          const errorStatus = JSON.parse(error.message).status;
          console.error('Socket connection error:', errorStatus);

          setConnectionStatus('offline');

          if (reconnectIntervalId) {
            clearInterval(reconnectIntervalId);
          }
          // @ts-ignore
          if (errorStatus === 401 || errorStatus === 403) {
            // Si el error es 401 o 403, sigue reintentando cada 10 segundos

            reconnectIntervalId = setInterval(connectSocket, 10000);
          } else if (errorStatus === 400) {
            // If the error is 400, close the websocket connection and show the modal to the user
            socket.disconnect();

            setSessionMessageModalOpen({
              open: true,
              message: 'modal.messageMaximumSessions',
            });

            dispatch(setExpirationState('expired'));
          }
        });

        socket.on('chat message', (data) => {
          console.log('Received message:', data);
          if (data === '123') {
            socket.disconnect();
          }
        });
      }
      setSocketConnection(socket);

      return () => {
        socket.disconnect();
        if (reconnectIntervalId) {
          clearInterval(reconnectIntervalId);
        }
      };
    };

    // Connect immediately
    connectSocket();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, token]);

  return (
    <socketContext.Provider
      value={{
        subscribeEvent,
        unsubscribeEvent,
        emitEvent,
        listenToAllEvents,
        stopListeningToAllEvents,
        socketConnectionState,
        connectionStatus,
        sessionMessageModalOpen,
        setSessionMessageModalOpen,
      }}
    >
      {children}
    </socketContext.Provider>
  );
};

export default SocketProvider;
