import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
// Utils
import initRequestData from '../../functions/initRequestData';
import contactsSliceService from './contactsSliceService';
// Types
import InitRequestDataReturn from '../../types/InitRequestDataReturn';
import { ContactsSliceState } from './types/ContactsSliceState';
import { Pagination } from './types/Pagination';

const initialState: ContactsSliceState = {
  status: {
    fetch: 'idle',
  },
  contacts: [],
  pagination: null,
};

export const fetchContacts = createAsyncThunk(
  'Contacts/fetchContacts',
  async (searchParams: Pagination, { dispatch }) => {
    const { jwtToken, URL_CONTACTS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;

    return await contactsSliceService.fetch(jwtToken, URL_CONTACTS, searchParams);
  }
);

const contactsSlice = createSlice({
  name: 'contacts',
  initialState,
  reducers: {
    resetContacts: (state) => {
      state.contacts = null;
      state.status.fetch = 'idle';
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchContacts.pending, (state) => {
      state.status.fetch = 'loading';
    });
    builder.addCase(fetchContacts.fulfilled, (state, action) => {
      state.status.fetch = 'succeeded';
      console.log('Succeeded fetchContacts:', action.payload);

      state.contacts = action.payload.contactos;
      state.pagination = action.payload.paginacion;
    });
    builder.addCase(fetchContacts.rejected, (state, action) => {
      console.log('Error fetchContacts:', action.error);

      state.status.fetch = 'rejected';
    });
  },
});

export const { resetContacts } = contactsSlice.actions;

export default contactsSlice.reducer;
