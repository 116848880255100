export const style = {
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingY: '7px',
    paddingX: '11px',
    borderRadius: '10px',
    border: '1px solid #E0E0E0',
    marginBottom: '15px',
  },
};
