import { useContext } from 'react';
// Context
import SipContext from '../../../../../../../../../../../../context/Sip/SipContext';
// Components/ui
import { style } from './style';
import { Box, Slide } from '@mui/material';
import { CallList, Header } from './components';

type TransferCallWindowProps = {
  isOpen: boolean;
};
const TransferCallWindow = ({ isOpen }: TransferCallWindowProps) => {
  const { transfer } = useContext(SipContext);

  return (
    <Slide direction="up" mountOnEnter unmountOnExit in={isOpen}>
      <Box sx={style.container}>
        <Header searchField={transfer.search} />
        <CallList
          callOptions={transfer.callOptions}
          callSelector={transfer.callSelector}
        />
      </Box>
    </Slide>
  );
};

export default TransferCallWindow;
