import axios from 'axios';
// Utils
import getRequestConfig from '../../functions/getRequestConfig';
// Types
import { IConversation } from '@trii/types/dist/Conversations';
async function fetchFloatingWindowChats(URL: string, jwt: string, uid: string) {
  const config = getRequestConfig.basic(jwt);
  const response = await axios.get<IConversation[]>(
    `${URL}/FloatingWindows/${uid}`,
    config
  );

  return response.data;
}

async function deleteFloatingWindow(
  URL: string,
  jwt: string,
  uid: string,
  data: { chatId: string; conversationId: string }
) {
  const config = getRequestConfig.basic(jwt);

  await axios.delete(`${URL}/FloatingWindows/${uid}`, {
    ...config,
    data,
  });
}

export default {
  fetchFloatingWindowChats,
  deleteFloatingWindow,
};
