import { useTranslation } from 'react-i18next';
// Components/ui
import { Modal, Box, Typography } from '@mui/material';

interface SessionMessageModalProps {
  open: boolean;
  message: string;
}

const SessionMessageModal = ({ open, message }: SessionMessageModalProps) => {
  const { t } = useTranslation();
  return (
    <Modal open={open}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          border: '2px solid #000',
          boxShadow: 24,
          p: 4,
        }}
      >
        <Typography id="session-limit-modal-title" variant="h6" component="h2">
          {t(message)}
        </Typography>
      </Box>
    </Modal>
  );
};

export default SessionMessageModal;
