import { useRef, useCallback } from 'react';

type InfiniteScrollCallback = () => Promise<void>;

const useInfiniteScroll = (callback: InfiniteScrollCallback) => {
  const isScrollingRef = useRef(false);
  const prevScrollTopRef = useRef(0);

  const handleScroll = useCallback(
    async (e: React.UIEvent<HTMLDivElement>) => {
      const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
      const bottom = scrollHeight - scrollTop - 3 <= clientHeight;
      const scrollingDown = scrollTop > prevScrollTopRef.current;

      prevScrollTopRef.current = scrollTop;

      if (bottom && scrollingDown && !isScrollingRef.current) {
        isScrollingRef.current = true;
        await callback();
        isScrollingRef.current = false;
      }
    },
    [callback]
  );

  return handleScroll;
};

export default useInfiniteScroll;
