import React from 'react';
import { useTranslation } from 'react-i18next';
// Components/ui
import { Button } from '@mui/material';
import { style } from './style';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

type Props = {
  showInternalContacts: () => void;
  showExternalContacts: () => void;
  currentlyShowing: 'internal' | 'external';
};

const NavigationButton: React.FC<Props> = ({
  showInternalContacts,
  showExternalContacts,
  currentlyShowing,
}) => {
  const { t } = useTranslation();

  const handleClick = () => {
    if (currentlyShowing === 'internal') {
      showExternalContacts();
    } else {
      showInternalContacts();
    }
  };

  const buttonText =
    currentlyShowing === 'internal'
      ? t('calls.externalContacts')
      : t('calls.internalContacts');
  const buttonIcon =
    currentlyShowing === 'internal' ? <ArrowForwardIcon /> : <ArrowBackIcon />;

  return (
    <Button sx={style.button} onClick={handleClick} variant="outlined">
      {buttonText}
      {buttonIcon}
    </Button>
  );
};

export default NavigationButton;
