// Components/ui
import { Chat, PanelButton } from './components';
// Redux
import { useSelector } from 'react-redux';
import { selectOpenChats } from '../../../../redux/features/chatsSlice/selectors';

const chatsMock: Chat[] = [
  {
    id: '1',
    minimized: true,
    messages: [
      {
        id: '1',
        text: 'Hola',
        user: { id: '1', name: 'Ezequiel' },
      },
      {
        id: '2',
        text: 'Chau',
        user: { id: '2', name: 'Sebrastian' },
      },
    ],
    user: { id: '1', name: 'Sebrastian' },
  },
  {
    id: '2',
    minimized: false,
    messages: [
      {
        id: '1',
        text: 'Todo bien?',
        user: { id: '1', name: 'Ezequiel' },
      },
      {
        id: '2',
        text: 'No',
        user: { id: '2', name: 'Lautaro' },
      },
    ],
    user: { id: '2', name: 'Lautaro' },
  },
];

const Chats = () => {
  const openChats = useSelector(selectOpenChats);

  return (
    <>
      <PanelButton />
      <div
        style={{
          display: 'flex',
          gap: 6,
          right: '2.5rem',
          bottom: '0',
          position: 'absolute',
          alignItems: 'flex-end',
        }}
      >
        {openChats.map((data) => (
          <Chat key={data.id} data={data} />
        ))}
      </div>
    </>
  );
};

export default Chats;
