import { useTranslation } from 'react-i18next';
// Components/ui
import { Box, Avatar, Typography, IconButton, Chip, Tooltip } from '@mui/material';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import { style } from './style';
// Types
import { ICallLog } from '@trii/types/dist/Calls';
// Context
import { useContext } from 'react';
import SipContext from '../../../../../../../../../../context/Sip/SipContext';
import { StatusIcon } from './components';

type CallItemProps = {
  data: ICallLog;
};

const formatDuration = (seconds) => {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;
  return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
};

const CallItem = ({ data }: CallItemProps) => {
  const { t } = useTranslation();

  const { handleMakeCallFromExternal } = useContext(SipContext);

  const day = data.startStamp ? new Date(data.startStamp).toLocaleDateString() : '';
  const today = new Date().toLocaleDateString();

  const displayDay = day === today ? t('calls.today') : day;
  const time = data.startStamp ? new Date(data.startStamp).toLocaleTimeString() : '';
  const duration = formatDuration(data.duration);

  return (
    <Box sx={{ ...style.container, position: "relative" }}>
      <Tooltip title={data.remoteName}>

      <Avatar>
        {data.remoteName[0] !== undefined ? data.remoteName[0] : ""}
      </Avatar>
      </Tooltip>
      <Box flexGrow={1}>
        <Box display={"flex"} alignItems={"center"} sx={{
          maxWidth: '145px'
        }}>

          <Typography color={"text.primary"} sx={{
            overflow: 'hidden', textOverflow: 'ellipsis'
          }}>{data.remoteName}</Typography>
          <Box display={'flex'} marginLeft={'10px'}>
            <Chip
              size="small"
              label={
                <Typography color={"text.primary"} variant="caption">
                  {duration}
                </Typography>
              }
              sx={{
                borderRadius: "4px",
                height: "14px",
                "& span": {
                  fontSize: "0.625rem",
                  paddingX: "2px",
                },
              }}
            ></Chip>
          </Box>
        </Box>
        <Box sx={style.callInfoContainer}>
          <StatusIcon direction={data.direction} status={data.status} />
          <Typography color={"text.primary"} variant="caption">
            {displayDay} - {time}
          </Typography>
        </Box>
      </Box>
      <IconButton onClick={() => handleMakeCallFromExternal(data.remoteNumber)}>
        <LocalPhoneIcon />
      </IconButton>
    </Box>
  );
};

export default CallItem;
