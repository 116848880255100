import React, { Suspense } from 'react';
// Translation
import './i18n';
import { I18nextProvider } from 'react-i18next';
import i18next from 'i18next';
// Context provider
import DataProvider from './context/contextApi';
import StyleProvider from './style/styleProvider';
// Redux
import { Provider } from 'react-redux';
import store from './redux/store';
// Components/ui
import App from './App';
// Types
import RootProps from './types/RootProps';

interface Props {
  rootProps: RootProps;
}

const AppContainer = ({ rootProps }: Props) => {
  return (
    <Suspense fallback={null}>
      <I18nextProvider i18n={i18next}>
        <DataProvider>
          <StyleProvider>
            <Provider store={store}>
              <App rootProps={rootProps} />
            </Provider>
          </StyleProvider>
        </DataProvider>
      </I18nextProvider>
    </Suspense>
  );
};

export default AppContainer;
