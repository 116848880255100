import { useState } from 'react';
// Types
import { UseCallWindow } from './useCallWindow.type';

const useCallWindow = (): UseCallWindow => {
  const [transferCallWindowOpen, setTransferCallWindowOpen] = useState(false);
  const [callDialerOpen, setCallDialerOpen] = useState(true);

  function handleCloseTransferCallWindow() {
    setTransferCallWindowOpen(false);
    setTimeout(() => {
      setCallDialerOpen(true);
    }, 250);
  }

  function handleOpenTransferCallWindow() {
    setCallDialerOpen(false);
    setTransferCallWindowOpen(true);
  }
  return {
    transferCallWindowOpen,
    callDialerOpen,
    handleCloseTransferCallWindow,
    handleOpenTransferCallWindow,
  };
};

export default useCallWindow;
