// Redux
import { useSelector } from 'react-redux';
import { selectUsersList } from '../../../../../../../../../../redux/features/usersSlice/selectors';
// Components.ui
import { Box } from '@mui/material';
import { style } from './style';
import { InternContactItem } from './components';

const InternalContacts = () => {
  const users = useSelector(selectUsersList);

  const contactItems = users?.map((user) => (
    <InternContactItem key={user.id} user={user} />
  ));

  return (
    <Box
      sx={{
        ...style.container,
        height: 'calc(-275px + 100vh)',
        marginRight: '-17px',
      }}
    >
      {contactItems}
    </Box>
  );
};

export default InternalContacts;
