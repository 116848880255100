export const style = {
  container: {
    userSelect: 'none',
    display: 'flex',
    flexDirection: 'column',
  },
  infoContainer: {
    padding: '0.5rem 0.3rem',
    '&:hover': {
      backgroundColor: 'rgba(0,0,0,0.1)',
      cursor: 'pointer',
    },
    display: 'flex',
    gap: '10px',
    alignItems: 'center',
    borderRadius: '0.25rem',
  },
};
