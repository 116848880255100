import axios from 'axios';
// Helper
import getRequestConfig from '../../functions/getRequestConfig';
import { UserTrii } from '@trii/types/dist/Users';
// Types

const fetchUserInfo = async (jwtToken: string, URL: string): Promise<UserTrii> => {
  const config = getRequestConfig.basic(jwtToken);
  const response = await axios.get<UserTrii>(URL, config);

  return response.data;
};

const userSliceService = { fetchUserInfo };

export default userSliceService;
