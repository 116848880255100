import React from 'react';
// Components/ui
import { Typography, Avatar, Box } from '@mui/material';

interface Props {
  data: Message;
}

const Message = ({ data }: Props) => {
  return (
    <Box
      key={data.id}
      style={{
        display: 'flex',
        marginBottom: '0.6rem',
        gap: 9,
      }}
    >
      <Avatar sx={{ width: 26, height: 26 }}></Avatar>
      <Box
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
        }}
      >
        <Typography variant="body2" color="text.primary" sx={{ fontWeight: 'bold' }}>
          {data.user.name}
        </Typography>
        <Typography
          variant="caption"
          style={{ color: '#a0aec0', textAlign: 'right' }}
        >
          {'12 abr, 16:20'}
        </Typography>
        <Typography variant="body2" color="text.primary">{data.text}</Typography>
      </Box>
    </Box>
  );
};

export default Message;
