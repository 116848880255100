// Context
import { useContext } from 'react';
import { StyleContext } from '../../../../../../../../../../style/styleProvider';
// Components/ui
import { SearchInput } from './components/ExternalContactItem/components';
import { ExternalContactItem } from './components';
import { style } from './style';
import { Box } from '@mui/material';
// Hooks
import useSearchContacts from './hook/useSearchContacts/useSearchContacts';

const ExternalContacts = () => {
  const { mediaQuery } = useContext(StyleContext);
  const { handleChange, isLoading, search, contactList, handleScroll } =
    useSearchContacts();
  const height =
    mediaQuery.sm || mediaQuery.md ? '300px' : mediaQuery.lg ? '430px' : '460px';

  const contactItems = contactList.map((contact) => (
    <ExternalContactItem key={contact.id} contact={contact} />
  ));

  return (
    <Box sx={style.container}>
      <SearchInput
        handleChange={handleChange}
        isLoading={isLoading}
        search={search}
      />
      <div
        style={{
          height: 'calc(100vh - 320px)',
          overflowY: 'auto',
          marginRight: '-17px'
        }}
        onScroll={(e) => handleScroll(e)}
      >
        {contactItems}
      </div>
    </Box>
  );
};

export default ExternalContacts;
