export const style = {
  button: {
    position: 'absolute',
    top: '3px',
    left: '4px',
    height: '17px',
    width: '17px',
  },
  icon: { height: '16px', width: '16px', color: 'primary.dark' },
};
