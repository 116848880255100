// Components/ui
import { Box, Typography } from '@mui/material';
import { style } from './style';
// Context
import { useContext } from 'react';
import SipContext from '../../../../../../../../../../../../../../../../context/Sip/SipContext';
// Types
import { CallStatus } from '../../../../../../../../../../../../../../../../context/Sip/hooks/useUserAgent/types/CallStatus';

/**
 * Renders a set of action buttons based on certain conditions.
 * @returns JSX.Element
 */
const ActionButtons = (): JSX.Element => {
  const {
    transfer: { callSelector, transferWindow },
    handleDirectTransfer,
    handleMakeTransferCall,
    handleHangUpTransferCall,
    handleCompleteAttendedTransfer,
    userAgent,
  } = useContext(SipContext);

  // const isTransferedCallAccepted = transferUserAgent.callStatus === CallStatus.Answered;
  const isTransferedCallAccepted =
    userAgent.transferCallStatus === CallStatus.Answered;

  const renderButtons = () => {
    if (callSelector.value !== '') {
      if (transferWindow === 'general') {
        return (
          <>
            <Box sx={style.button}>
              <div onClick={handleDirectTransfer}>
                <Typography color="text.primary" variant="caption">
                  Transf. Directa
                </Typography>
              </div>
            </Box>
            <Box sx={style.button}>
              <div onClick={handleMakeTransferCall}>
                <Typography color="text.primary" variant="caption">
                  Transf. Supervisada
                </Typography>
              </div>
            </Box>
          </>
        );
      } else if (transferWindow === 'supervised') {
        return (
          <>
            <Box sx={style.button}>
              <div onClick={handleHangUpTransferCall}>
                <Typography color="text.primary" variant="caption">
                  Cancelar
                </Typography>
              </div>
            </Box>
            <Box sx={style.button}>
              <div
                onClick={isTransferedCallAccepted && handleCompleteAttendedTransfer}
              >
                <Typography
                  color={isTransferedCallAccepted ? 'text.primary' : 'text.disabled'}
                  variant="caption"
                >
                  Transferir
                </Typography>
              </div>
            </Box>
          </>
        );
      }
    }
    return null;
  };

  return <Box sx={style.container}>{renderButtons()}</Box>;
};

export default ActionButtons;
