import { combineReducers } from '@reduxjs/toolkit';
// Reducers
import poolReducer from './features/poolSlice/poolSlice';
import sessionReducer from './features/sessionSlice/sessionSlice';
import spaceSlice from './features/spaceSlice/spaceSlice';
import spacesSlice from './features/spacesSlice/spacesSlice';
import themeReducer from './features/themeSlice/themeSlice';
import userReducer from './features/userSlice/userSlice';
import usersReducer from './features/usersSlice/usersSlice';
import contactsReducer from './features/contactsSlice/contactsSlice';
import callReducer from './features/callSlice/callSlice';
import chatsReducer from './features/chatsSlice/chatsSlice';

const rootReducer = combineReducers({
  Spaces: spacesSlice,
  Space: spaceSlice,
  Session: sessionReducer,
  Pool: poolReducer,
  Theme: themeReducer,
  User: userReducer,
  Users: usersReducer,
  Contacts: contactsReducer,
  Calls: callReducer,
  Chats: chatsReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
