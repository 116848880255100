// Components/ui
import { Box, Avatar, Typography, IconButton } from '@mui/material';
import { style } from './style';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
// Types
import { UserInfo } from '@trii/types/dist/Users';
// Context
import { useContext } from 'react';
import SipContext from '../../../../../../../../../../../../context/Sip/SipContext';

type InternContactItemProps = {
  user: UserInfo;
};
const InternContactItem = ({ user }: InternContactItemProps) => {
  const { handleMakeCallFromExternal } = useContext(SipContext);

  const firstNameLetter = user.name[0].toUpperCase();

  return (
    <Box display={'flex'} padding={'0.5rem 0.3rem'}>
      <Box sx={style.infoContainer}>
        <Avatar>{firstNameLetter}</Avatar>
        <Box display={'flex'} flexDirection={'column'}>
          <Typography color={'text.primary'} variant="caption">
            {user.name}
          </Typography>
          <Typography color={'text.primary'} variant="caption">
            {user.sipNumber}
          </Typography>
        </Box>
      </Box>
      {user.sipNumber && user.sipNumber !== '' && (
        <IconButton onClick={() => handleMakeCallFromExternal(user.sipNumber)}>
          <LocalPhoneIcon />
        </IconButton>
      )}
    </Box>
  );
};

export default InternContactItem;
