import { createContext } from 'react';
// Types
import { UseTimer } from './hooks/useTimer/useTimer.type';
import { UseDial } from './hooks/useDial/useDial.type';
import { UseUserAgent } from './hooks/useUserAgent/useUserAgent.type';
import { UseCallWindow } from './hooks/useCallWindow/useCallWindow.type';
import { UseTransfer } from './hooks/useTransfer/useTransfer.type';
import { UseCallNotification } from './hooks/useCallNotification/useCallNotification.type';
import { UseDrawer } from './hooks/useDrawer/useDrawer.type';

export type SipContextType = {
  callTimer: UseTimer;
  dial: UseDial;
  userAgent: UseUserAgent;
  // transferUserAgent: UseUserAgent;
  callWindow: UseCallWindow;
  notification: UseCallNotification;
  transfer: UseTransfer;
  drawer: UseDrawer;
  handleAnswerNotificationCall: () => void;
  handleRejectNotificationCall: () => void;
  handleMakeCall: () => void;
  handleMakeCallFromExternal: (number: string) => void;
  handleMakeVoiceMailCall: () => void;
  handleMakeTransferCall: () => void;
  handleDirectTransfer: () => void;
  handleHangUpTransferCall: () => void;
  handleCompleteAttendedTransfer: () => void;
  handleDialPress: (value: string) => void;
};

export const SipContext = createContext<SipContextType>({
  callTimer: { time: '0:00', start: () => {}, reset: () => {} },
  drawer: {
    drawerOpen: false,
    handleOpenDrawer: () => {},
    handleCloseDrawer: () => {},
    tabValue: 0,
    handleDrawerTabChange: () => {},
  },
  notification: {
    show: false,
    handleShow: () => {},
    handleHide: () => {},
    callInfo: null,
    setCallInfo: () => {},
  },
  callWindow: {
    transferCallWindowOpen: false,
    callDialerOpen: false,
    handleCloseTransferCallWindow: () => {},
    handleOpenTransferCallWindow: () => {},
  },
  transfer: {
    callSelector: {
      changeValue: () => {},
      value: '',
      onChange: () => {},
      reset: () => {},
    },
    search: {
      type: 'text',
      value: '',
      onChange: () => {},
      reset: () => {},
    },
    callOptions: [],
    transferWindow: 'general',
    setGeneralTransferWindow: () => {},
    setSupervisedTransferWindow: () => {},
    reset: () => {},
  },
  dial: {
    dialValue: '',
    handleChange: () => {},
    handleDial: () => {},
    handleDialBackspace: () => {},
    reset: () => {},
    setValue: () => {},
  },
  userAgent: {
    inviterInfo: null,
    holdStatus: false,
    transportStatus: 'disconnected',
    callStatus: 0,
    userAgent: null,
    session: null,
    muteStatus: false,
    makeCall: () => {},
    endCall: () => {},
    acceptCall: () => {},
    blindTransferCall: () => {},
    attendedTransferCall: () => {},
    hold: () => {},
    unhold: () => {},
    sendDTMF: () => {},
    mute: () => {},
    unmute: () => {},
    setupRemoteMedia: () => {},
    stopUa: () => {},
    endTransferCall: () => {},
    transferCallStatus: 0,
    completeAttendedTransfer: () => {},
  },

  handleMakeCall: () => {},
  handleMakeCallFromExternal: () => {},
  handleMakeTransferCall: () => {},
  handleDirectTransfer: () => {},
  handleAnswerNotificationCall: () => {},
  handleRejectNotificationCall: () => {},
  handleMakeVoiceMailCall: () => {},
  handleHangUpTransferCall: () => {},
  handleCompleteAttendedTransfer: () => {},
  handleDialPress: () => {},
});

export default SipContext;
