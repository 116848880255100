export const style = {
  container: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '200px',
    gap: '1rem',
  },
  hangUpButton: {
    height: '50px',
    width: '50px',
    border: '1px solid red',
  },
  muteButton: {
    height: '40px',
    width: '40px',
    border: '1px solid lightgray',
  },
};
