import { useState } from 'react';
// Types
import { UseDial } from './useDial.type';
const useDial = (): UseDial => {
  const [dialValue, setDialValue] = useState<string>('');

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    const value = event.target.value;
    const filteredValue = value.replace(/[^0-9*#]/g, '');
    setDialValue(filteredValue);
  }

  function setValue(value: string) {
    setDialValue(value);
  }

  function focusInput() {
    const input = document.getElementById('dial-visor') as HTMLInputElement;
    if (input) {
      input.focus();
      input.setSelectionRange(input.value.length, input.value.length);
    }
  }
  function reset() {
    setDialValue('');
  }

  function handleDial(value: string) {
    focusInput();
    setDialValue((prevValue) => prevValue + value);
  }

  function handleDialBackspace() {
    focusInput();
    setDialValue((prevValue) => prevValue.slice(0, -1));
  }

  return {
    dialValue,
    handleChange,
    handleDial,
    handleDialBackspace,
    reset,
    setValue,
  };
};

export default useDial;
