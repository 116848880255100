export const style = {
  container: {
    marginTop: '200px',
    display: 'flex',
    gap: '20px',
    alignItems: 'center',
  },
  rejectButton: {
    height: '40px',
    width: '40px',
    border: '1px solid red',
  },
  answerButton: {
    height: '50px',
    width: '50px',
    border: '1px solid green',
  },
};
