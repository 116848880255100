import { useEffect } from 'react';
// Components/ui
import { Tabs, Tab, Box } from '@mui/material';
import DialpadIcon from '@mui/icons-material/Dialpad';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import VoicemailIcon from '@mui/icons-material/Voicemail';
import { fetchLogs } from '../../../../../../../../redux/features/callSlice/callSlice';
import { useAppDispatch } from '../../../../../../../../hooks/useAppDispatch';

type DrawerTabsProps = {
  value: number;
  handleChange: (event: React.SyntheticEvent, newValue: number) => void;
};

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
const DrawerTabs = ({ value, handleChange }: DrawerTabsProps) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (value === 2) {
      const fetchLogsData = {
        limit: 50,
        pos: '',
      };

      dispatch(fetchLogs(fetchLogsData));
    }
  }, [value]);

  return (
    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
      <Tabs centered value={value} onChange={handleChange}>
        <Tab
          icon={<DialpadIcon />}
          {...a11yProps(1)}
          sx={{ minWidth: 'auto', padding: '6px 24px' }}
        />
        <Tab
          icon={<AccountCircleIcon />}
          {...a11yProps(2)}
          sx={{ minWidth: 'auto', padding: '6px 24px' }}
        />
        <Tab
          icon={<AccessTimeIcon />}
          {...a11yProps(3)}
          sx={{ minWidth: 'auto', padding: '6px 24px' }}
        />
        <Tab
          icon={<VoicemailIcon />}
          {...a11yProps(4)}
          sx={{ minWidth: 'auto', padding: '6px 24px' }}
        />
      </Tabs>
    </Box>
  );
};

export default DrawerTabs;
