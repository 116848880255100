import { useEffect } from 'react';

const useGlobalKeyDown = (handleKeyDown: (event: KeyboardEvent) => void) => {
  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);
};

export default useGlobalKeyDown;
