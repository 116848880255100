import {
  createSlice,
  createAsyncThunk,
  PayloadAction,
  createSelector,
} from '@reduxjs/toolkit';
import initRequestData from '../../functions/initRequestData';
// Types
import { RootState } from '../../rootReducer';
import { ISpaceInfo } from '@trii/types/dist/Spaces';
import InitRequestDataReturn from '../../types/InitRequestDataReturn';
import { SpaceSliceState } from './types/SpaceSliceState';
// Service
import spaceSliceService from './spaceSliceService';
import { RequestStatus } from '../../../types/reduxTypes';

const initialState: SpaceSliceState = {
  spaceInfo: null,
  URL: {
    USER: 'https://empresa1.mytrii.app/api/v1/settings/user',
    CONVERSATIONS: '',
    USERS: '',
    CONTACTS: '',
    CALLS: '',
  },
  setUrls: false,
  status: {
    fetch: 'idle',
  },
};

export const fetchSpaceInfo = createAsyncThunk(
  'space/getSpaceInfo',
  async (_, { dispatch }) => {
    const { jwtToken } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;

    const response = await spaceSliceService.fetchSpaceInfo(jwtToken);

    return response.data;
  }
);

const spaceSlice = createSlice({
  name: 'space',
  initialState,
  reducers: {
    setSpaceInfo: (state, action: PayloadAction<ISpaceInfo>) => {
      state.spaceInfo = action.payload;
    },
    setSpaceFetchStatus: (state, action: PayloadAction<RequestStatus>) => {
      state.status.fetch = action.payload;
    },
    setSpaceUrls: (state, action: PayloadAction<string>) => {
      state.URL.CONVERSATIONS = `https://agent-api.trii.app/api/v1/conversations/${action.payload}`;
      state.URL.USERS = `https://agent-api.trii.app/api/v1/settings/${action.payload}/users`;
      state.URL.CONTACTS = `https://agent-api.trii.app/api/v1/contacts/${action.payload}`;
      state.URL.CALLS = `https://agent-api.trii.app/api/v1/calls/${action.payload}`;
      state.setUrls = true;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchSpaceInfo.pending, (state) => {
      state.status.fetch = 'loading';
    });
    builder
      .addCase(
        fetchSpaceInfo.fulfilled,
        (state, action: PayloadAction<ISpaceInfo>) => {
          console.log('fetchSpaceInfo.fulfilled', action.payload);

          state.spaceInfo = action.payload;
          state.URL.CONVERSATIONS = `https://agent-api.trii.app/api/v1/conversations/${action.payload.id}`;
          state.URL.CONTACTS = `https://agent-api.trii.app/api/v1/contacts/${action.payload.id}`;
          state.URL.USERS = `https://agent-api.trii.app/api/v1/settings/${action.payload}/users`;
          state.URL.CALLS = `https://agent-api.trii.app/api/v1/calls/${action.payload}`;
          state.status.fetch = 'succeeded';
        }
      )
      .addCase(fetchSpaceInfo.rejected, (state) => {
        // state.status.fetch = 'failed';
        console.error('fetchSpaceInfo.rejected');
      });
  },
});

export const { setSpaceInfo, setSpaceFetchStatus, setSpaceUrls } =
  spaceSlice.actions;

const selectSpaceState = (state: RootState) => state.Space;
export const selectSpaceInfo = createSelector(
  selectSpaceState,
  (space) => space.spaceInfo
);
export const selectHaveUrlsBeenSet = createSelector(
  selectSpaceState,
  (space) => space.setUrls
);

export default spaceSlice.reducer;
