// Components/ui
import { style } from './style';
import { Box, Avatar, Typography } from '@mui/material';
import { NumbersBox } from './components';
import { useNumbersBox } from './hook/useNumbersBox/useNumbersBox';
// Types
import { IContact } from '@trii/types/dist/Contacts';

type ExternalContactItemProps = {
  contact: IContact;
};
const ExternalContactItem = ({ contact }: ExternalContactItemProps) => {
  const { open, changeOpen } = useNumbersBox();

  const contactFirstLetter = contact.name?.charAt(0).toUpperCase() || '';

  return (
    <Box sx={style.container}>
      <div onClick={changeOpen}>
        <Box sx={style.infoContainer}>
          <Avatar>{contactFirstLetter}</Avatar>
          <Typography color={'text.primary'} variant="caption">
            {contact.name}
          </Typography>
        </Box>
      </div>
      {open && <NumbersBox contactPhones={contact.phones} open={open} />}
    </Box>
  );
};

export default ExternalContactItem;
