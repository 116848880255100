import { createListenerMiddleware } from '@reduxjs/toolkit';
// Types
import { RootState } from './rootReducer';
// Actions
import { updateUserPresence } from './features/usersSlice/usersSlice';

const listenerMiddleware = createListenerMiddleware();

listenerMiddleware.startListening({
  actionCreator: updateUserPresence,
  effect: (_action, listenerApi) => {
    const state = listenerApi.getState() as RootState;
    const newUsers = state.Users.users;

    window.users = newUsers;
    window.dispatchEvent(new CustomEvent('usersChange'));
  },
});

export default listenerMiddleware;
