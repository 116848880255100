import { useEffect, useState } from 'react';
// Redux
import { useSelector } from 'react-redux';
import { selectUser } from '../../redux/features/userSlice/userSlice';
// Types
import { IconItem } from './types';
// UI
import GroupIcon from '@mui/icons-material/Group';
import ChatIcon from '@mui/icons-material/Chat';
import PhoneIcon from '@mui/icons-material/Phone';
import BuildIcon from '@mui/icons-material/Build';
import {
  faCoins,
  faBullhorn,
  faTicket,
  faAddressBook,
  faCalendar,
  faPlay,
  faRotate,
  faSignature,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ModuleIconsContext, ModuleIconsContextType } from './ModuleIconsContext';

const ModuleIconsProvider = ({ children }) => {
  const user = useSelector(selectUser);

  const [enabledIcons, setEnabledIcons] = useState<{ [key: string]: boolean }>({});
  const iconsList: IconItem[] = [
    {
      id: 1,
      component: <GroupIcon style={{ fontSize: '1rem' }} />,
      enabled: user?.profile?.chat.enabled,
      moduleName: 'chat',
    },
    {
      id: 2,
      component: <ChatIcon style={{ fontSize: '0.9rem' }} />,
      enabled: user?.profile?.conversations.enabled,
      moduleName: 'conversations',
    },
    {
      id: 3,
      component: <PhoneIcon style={{ fontSize: '0.9rem' }} />,
      enabled: user?.profile?.calls.enabled,
      moduleName: 'calls',
    },
    {
      id: 4,
      component: <BuildIcon style={{ fontSize: '0.9rem' }} />,
      enabled: user?.profile?.service.enabled,
      moduleName: 'service',
    },
    {
      id: 5,
      component: <FontAwesomeIcon icon={faCoins} style={{ fontSize: '0.8rem' }} />,
      enabled: user?.profile?.debtCollection.enabled,
      moduleName: 'debtCollection',
    },
    {
      id: 6,
      component: (
        <FontAwesomeIcon icon={faBullhorn} style={{ fontSize: '0.8rem' }} />
      ),
      enabled: user?.profile?.marketing.enabled,
      moduleName: 'marketing',
    },
    {
      id: 7,
      component: <FontAwesomeIcon icon={faTicket} style={{ fontSize: '0.8rem' }} />,
      enabled: user?.profile?.tickets.enabled,
      moduleName: 'tickets',
    },
    {
      id: 8,
      component: (
        <FontAwesomeIcon icon={faAddressBook} style={{ fontSize: '0.8rem' }} />
      ),
      enabled: user?.profile?.contacts.enabled,
      moduleName: 'contacts',
    },
    {
      id: 9,
      component: (
        <FontAwesomeIcon icon={faCalendar} style={{ fontSize: '0.8rem' }} />
      ),
      enabled: user?.profile?.calendar.enabled,
      moduleName: 'calendar',
    },
    {
      id: 10,
      component: <FontAwesomeIcon icon={faPlay} style={{ fontSize: '0.8rem' }} />,
      enabled: user?.profile?.crm.enabled,
      moduleName: 'crm',
    },
    {
      id: 11,
      component: <FontAwesomeIcon icon={faRotate} style={{ fontSize: '0.8rem' }} />,
      enabled: user?.profile?.datasync.enabled,
      moduleName: 'datasync',
    },
    {
      id: 12,
      component: (
        <FontAwesomeIcon icon={faSignature} style={{ fontSize: '0.8rem' }} />
      ),
      enabled: user?.profile?.signature.enabled,
      moduleName: 'signature',
    },
  ];

  const handleStorageChange = (event: StorageEvent) => {
    console.log('Storage event', event);
    if (event.key && event.key.startsWith('newevents_')) {
      const moduleName = event.key.replace('newevents_', '');
      // console.log("")
      const isEnabled = event.newValue === 'true';
      setEnabledIcons((prevState) => ({
        ...prevState,
        [moduleName]: isEnabled,
      }));
    }
  };

  const handleLocalStorageChange = (key: string, value: string) => {
    const event = new StorageEvent('storage', {
      key,
      newValue: value,
      oldValue: localStorage.getItem(key),
      storageArea: localStorage,
      url: window.location.href,
    });
    handleStorageChange(event);
  };

  useEffect(() => {
    const initialEnabledIcons: { [key: string]: boolean } = {};
    iconsList.forEach((icon) => {
      const storageKey = `newevents_${icon.moduleName}`;
      const isEnabled = localStorage.getItem(storageKey);
      initialEnabledIcons[icon.moduleName] =
        isEnabled !== null ? isEnabled === 'true' : false;
    });
    setEnabledIcons(initialEnabledIcons);
  }, []);

  useEffect(() => {
    window.addEventListener('storage', handleStorageChange);
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  const value: ModuleIconsContextType = {
    enabledIcons,
    iconsList,
    handleStorageChange,
    handleLocalStorageChange,
  };

  return (
    <ModuleIconsContext.Provider value={value}>
      {children}
    </ModuleIconsContext.Provider>
  );
};

export default ModuleIconsProvider;
