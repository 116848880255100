// Components/ui
import { Grid, Button } from '@mui/material';
import { style } from './style';

const dialButtons = Array.from({ length: 12 }, (_, i) =>
  i === 9 ? '*' : i === 10 ? '0' : i === 11 ? '#' : i + 1
);

type DialPadProps = { handleDial: (num: string) => void };
const DialPad = ({ handleDial }: DialPadProps) => {
  return (
    <Grid container spacing={3} justifyContent="center">
      {dialButtons.map((num, index) => (
        <Grid item key={index}>
          <Button
            variant="text"
            sx={style.dialButton}
            onClick={() => handleDial(num.toString())}
          >
            {num}
          </Button>
        </Grid>
      ))}
    </Grid>
  );
};

export default DialPad;
