import { createContext } from 'react';
// Types
import { IconItem } from './types';

export type ModuleIconsContextType = {
  enabledIcons: { [key: string]: boolean };
  handleStorageChange: (event: StorageEvent) => void;
  handleLocalStorageChange: (key: string, value: string) => void;
  iconsList: IconItem[];
};

export const ModuleIconsContext = createContext<ModuleIconsContextType>({
  enabledIcons: {},
  iconsList: [],
  handleStorageChange: () => {},
  handleLocalStorageChange: () => {},
});
