import { useState } from 'react';
// Types
import { UseDrawer } from './useDrawer.type';

const useDrawer = (): UseDrawer => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const initialIsPinned = localStorage.getItem('callPopupIsPinned') === 'true'; 

  const handleDrawerTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const handleOpenDrawer = () => {
    localStorage.setItem('mainLayoutWidthReducer', true.toString()); 
    window.dispatchEvent(
      new CustomEvent("mainLayoutWidthReducerChanged", {
        detail: true.toString(),
      })
    );

    setDrawerOpen(true);
  };

  const handleCloseDrawer = () => {
      localStorage.setItem("mainLayoutWidthReducer", false.toString());
      window.dispatchEvent(
        new CustomEvent("mainLayoutWidthReducerChanged", {
          detail: false,
        })
      );
      setDrawerOpen(false);
  };

  return {
    drawerOpen,
    handleOpenDrawer,
    handleCloseDrawer,
    tabValue,
    handleDrawerTabChange,
  };
};

export default useDrawer;
