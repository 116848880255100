import { Height } from '@mui/icons-material';
import { keyframes } from '@mui/system';


export const style = {
  button: {
    userSelect: 'none',
    display: 'flex',
    alignItems: 'center',
    minHeight: '8rem',
    borderTopLeftRadius: '10px',
    borderBottomLeftRadius: '10px',
    backgroundColor: 'primary.main',
    cursor: 'pointer',
    transition: 'all 0.15s ease-in-out',  
    flexDirection:  'column',
    justifyContent: 'center',
    position: 'relative',
  },
  textContainer: {
    transform: 'rotate(90deg)',
    borderTop: '0.2rem solid white',
    paddingTop: '0.1rem',
  },
};
const pulse = keyframes`
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
`;

const pulseStyle = {
  animation: `${pulse} 1.5s infinite alternate`,
};

// Define the style for the PhoneIcon
export const phoneIconStyle = {
  borderRadius: '50%', // Apply border radius
  ...pulseStyle, // Apply pulsating animation
  position: 'absolute',
  bottom: '10px',
  left: '5px',
  border: '1px solid',
  color: (theme) =>theme.palette.error.main,
  width: '5px',
  height: '5px',
  '& svg': {
    width: '0.6em',
    Height: '0.6em',
  }



};