// Components/ui
import { Grid, IconButton } from '@mui/material';
import PhoneIcon from '@mui/icons-material/Phone';
import BackspaceIcon from '@mui/icons-material/Backspace';
import { style } from './style';
// Hooks
import useGlobalKeyDown from '../../../../../../../../../../../../hooks/useGlobalKeyDown';

type ActionButtonsProps = {
  handleDialBackspace: () => void;
  handleMakeCall: () => void;
};

const ActionButtons = ({
  handleDialBackspace,
  handleMakeCall,
}: ActionButtonsProps) => {
  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'Enter') {
      handleMakeCall();
    }
  };

  useGlobalKeyDown(handleKeyDown);

  return (
    <Grid
      container
      sx={style.container}
      alignItems={'center'}
      justifyContent="center"
      spacing={2}
    >
      <Grid item>
        <IconButton disabled>
          <PhoneIcon sx={{ color: 'transparent' }} />
        </IconButton>
      </Grid>
      <Grid item>
        <IconButton onClick={handleMakeCall} sx={style.callButton} color="success">
          <PhoneIcon />
        </IconButton>
      </Grid>
      <Grid item>
        <IconButton onClick={handleDialBackspace} color="warning">
          <BackspaceIcon />
        </IconButton>
      </Grid>
    </Grid>
  );
};

export default ActionButtons;
