import { useTranslation } from 'react-i18next';
import { useState } from 'react';
// Components/ui
import { Box, Slide, Typography } from '@mui/material';
import { style } from './style';
import { AvatarChatItem } from './components';
import { useSelector } from 'react-redux';
import { selectChats } from '../../../../../../redux/features/chatsSlice/selectors';

const PanelButton = () => {
  const { i18n } = useTranslation();

  const [hovering, setHovering] = useState(false);

  const chats = useSelector(selectChats);

  const isEnglish = i18n.language === 'En';
  const slideWidth = 300;

  return (
    <Box
      sx={style.container}
      onMouseEnter={() => setHovering(true)}
      onMouseLeave={() => setHovering(false)}
    >
      <Box sx={{ ...style.button, width: hovering ? slideWidth : '26.78px' }}>
        <Box
          sx={{
            ...style.textContainer,
          }}
        >
          <Typography color={'white'}>Chats</Typography>
        </Box>
      </Box>
      <Slide direction="left" in={hovering} mountOnEnter unmountOnExit>
        <Box sx={style.drawer}>
          {chats.map((data) => (
            <AvatarChatItem data={data} key={data.id}></AvatarChatItem>
          ))}
        </Box>
      </Slide>
    </Box>
  );
};

export default PanelButton;
