import { Box, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { style } from './style';
// Hooks
import useGlobalKeyDown from '../../../../../../../../hooks/useGlobalKeyDown';
import RemoveIcon from '@mui/icons-material/Remove';
import PushPinIcon from '@mui/icons-material/PushPin';
import PushPinOutlinedIcon from '@mui/icons-material/PushPinOutlined';
import { useEffect, useRef, useState } from 'react';
type CloseButtonProps = {
  onClick: () => void;
};
const CloseButton = ({ onClick }: CloseButtonProps) => {
  const initialIsPinned = localStorage.getItem('callPopupIsPinned') === 'true'; 
  const [callPopupIsPinned, setCallPopupIsPinned] = useState<boolean>(initialIsPinned !== null ? initialIsPinned : true);

  useEffect(() => {
    // Update the state when the initial value changes
    setCallPopupIsPinned(initialIsPinned !== null ? initialIsPinned : true);
  }, [initialIsPinned]);

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === "Escape" && !callPopupIsPinned) {
      localStorage.setItem("mainLayoutWidthReducer", false.toString());
      window.dispatchEvent(
        new CustomEvent("mainLayoutWidthReducerChanged", {
          detail: false,
        })
      );
    
      onClick();
    }
  };

  useGlobalKeyDown(handleKeyDown);

  // Función para alternar el estado de "pinned"
  const togglePinned = () => {
    const newState = !callPopupIsPinned;
    setCallPopupIsPinned(newState);
    localStorage.setItem('callPopupIsPinned', newState.toString()); // Almacenar el nuevo estado en localStorage
    localStorage.setItem('mainLayoutWidthReducer', newState.toString()); 
  
    // Emitir un evento para notificar a otros microfrontends sobre el cambio
    window.dispatchEvent(new CustomEvent('callPopupIsPinnedChanged', { detail: newState }));
    window.dispatchEvent(new CustomEvent('mainLayoutWidthReducerChanged', { detail: newState }));
  }

  return (
    <Box sx={style.container}>
      {callPopupIsPinned ? (
        <IconButton size="small" onClick={togglePinned}>
          <PushPinIcon sx={{ color: "primary" }} fontSize="small" />
        </IconButton>
      ) : (
        <IconButton size="small" onClick={togglePinned}>
          <PushPinOutlinedIcon sx={{ color: "primary" }} fontSize="small" />
        </IconButton>
      )}
      <IconButton onClick={onClick} size="small">
        <CloseIcon fontSize="small" />
      </IconButton>
    </Box>
  );
};

export default CloseButton;
