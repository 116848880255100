import { Box } from '@mui/material';
import useInfiniteScroll from './hooks/useInfiniteScroll';

interface PhoneTabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
  onScrollEnd?: () => Promise<void>;
}

function PhoneTabPanel(props: PhoneTabPanelProps) {
  const { children, value, index, onScrollEnd, ...other } = props;

  const handleScroll = useInfiniteScroll(onScrollEnd || (() => Promise.resolve()));

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box
          onScroll={handleScroll}
          sx={{
            p: '1.25rem',
            pb: 0,
            overflowY: 'auto',
            maxHeight: 'calc(100vh - 170px)',
          }}
        >
          {children}
        </Box>
      )}
    </Box>
  );
}

export default PhoneTabPanel;
