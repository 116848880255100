// Components/ui
import { Avatar, Box, Typography } from '@mui/material';
import { styled } from '@mui/system';
// Types
import { ChatType, IConversation } from '@trii/types/dist/Conversations';
import { addOpenChatId } from '../../../../../../../../redux/features/chatsSlice/chatsSlice';
import { useAppDispatch } from '../../../../../../../../hooks/useAppDispatch';
import { useSelector } from 'react-redux';
import { selectIsChatOpen } from '../../../../../../../../redux/features/chatsSlice/selectors';

interface AvatarChatItemProps {
  data: IConversation;
}

const StyledBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: '0.25rem',
  justifyItems: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  borderRadius: '10px',
  padding: '0.5rem',
  cursor: 'pointer',
  transition: 'background-color 0.15s',
  backgroundColor: 'transparent',
  width: '75px',
  '&:hover': {
    backgroundColor: theme.palette.primary.light,
  },
}));

const StyledAvatar = styled(Avatar)({
  width: 30,
  height: 30,
});

const StyledTypography = styled(Typography)({
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  textAlign: 'center',
  width: '100%',
});

const AvatarChatItem = ({ data }: AvatarChatItemProps) => {
  const dispatch = useAppDispatch();

  const isChatOpen = useSelector(selectIsChatOpen(data.id));

  function handleClick() {
    if (!isChatOpen) {
      dispatch(addOpenChatId(data.id));
    }
  }

  return (
    <StyledBox>
      <StyledAvatar onClick={handleClick} src={data.chatImage} />
      <StyledTypography color="text.primary" variant="caption">
        {data.type === ChatType.EXTERNAL ? data.channelInfo.name : data.chatName}
      </StyledTypography>
    </StyledBox>
  );
};

export default AvatarChatItem;
