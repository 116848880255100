import { useState } from 'react';
// Types
import { UseNumbersBox } from './useNumbersBox.type';

export const useNumbersBox = (): UseNumbersBox => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const changeOpen = () => {
    open ? handleClose() : handleOpen();
  };

  return {
    open,
    changeOpen,
  };
};
