import { CallDirection, CallStatus } from '@trii/types/dist/Calls';
import CallReceivedIcon from '@mui/icons-material/CallReceived';
import CallMissedIcon from '@mui/icons-material/CallMissed';
import CallSplitIcon from '@mui/icons-material/CallSplit';
import CallMadeIcon from '@mui/icons-material/CallMade';
import { style } from './style';
import { Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';

type StatusIconProps = {
  direction: CallDirection;
  status: CallStatus;
};

const StatusIcon = ({ status, direction }: StatusIconProps) => {
  const {t} = useTranslation()
  if (direction === CallDirection.INBOUND) {
    if (
      status === CallStatus.ANSWERED ||
      status === CallStatus.BUSY ||
      status === CallStatus.FAILED
    ) {
      if (status === CallStatus.ANSWERED) {
        return (
          <Tooltip title={t(`status.${status}`)}>
            <CallReceivedIcon sx={style.icon} color="success" />
          </Tooltip>
        );
      } else {
        return (
          <Tooltip title={t(`status.${status}`)}>
            <CallReceivedIcon sx={style.icon} color="disabled" />
          </Tooltip>
        );
      }
    } else if (
      status === CallStatus.NO_ANSWER ||  
      status === CallStatus.LOST ||
      status === CallStatus.ABANDONED
    ) {
      if (status === CallStatus.NO_ANSWER || status === CallStatus.ABANDONED) {
        return (
          <Tooltip title={t(`status.${status}`)}>
            <CallMissedIcon sx={style.icon} color="warning" />
          </Tooltip>
        );
      } else {
        return (
          <Tooltip title={t(`status.${status}`)}>
            <CallMissedIcon sx={style.icon} color="error" />
          </Tooltip>
        );
      }
    } else if (status === CallStatus.OVERFLOWED) {
      return (
        <Tooltip title={t(`status.${status}`)}>
          <CallSplitIcon sx={style.icon} color="disabled" />
        </Tooltip>
      );
    } else {
      return (
        <Tooltip title={t(`status.${status}`)}>
          <CallReceivedIcon sx={style.icon} color="disabled" />
        </Tooltip>
      );
    }
  } else {
    if (status === CallStatus.ANSWERED || status === CallStatus.NO_ANSWER) {
      if (status === CallStatus.ANSWERED) {
        return (
          <Tooltip title={t(`status.${status}`)}>
            <CallMadeIcon sx={style.icon} color="info" />
          </Tooltip>
        );
      } else {
        return (
          <Tooltip title={t(`status.${status}`)}>
            <CallMadeIcon sx={style.icon} color="warning" />
          </Tooltip>
        );
      }
    } else if (status === CallStatus.BUSY || status === CallStatus.FAILED) {
      return (
        <Tooltip title={t(`status.${status}`)}>
          <CallMadeIcon sx={style.icon} color="error" />
        </Tooltip>
      );
    } else {
      return (
        <Tooltip title={t(`status.${status}`)}>
          <CallMadeIcon sx={style.icon} color="disabled" />
        </Tooltip>
      );
    }
  }
};

export default StatusIcon;
