// Utils
import getRequestConfig from '../../functions/getRequestConfig';
import axios from 'axios';
// Types
import { Pagination } from './types/Pagination';
import { ContactData } from './types/FetchContactsData';
async function fetch(jwtToken: string, URL_CONTACTS: string, data: Pagination) {
  const config = getRequestConfig.contentTypeJson(jwtToken);
  const dataJson = JSON.stringify(data);
  const response = await axios.post<ContactData>(
    `${URL_CONTACTS}/Search`,
    dataJson,
    config
  );
  return response.data;
}

export default {
  fetch,
};
