import { useState } from 'react';
// Redux
import { useSelector } from 'react-redux';
import { selectUsersList } from '../../../../redux/features/usersSlice/selectors';
// Hooks
import useField from '../../../../hooks/useField';
import useRadio from '../../../../hooks/useRadio';
// Types
import { UseTransfer } from './useTransfer.type';

const useTransfer = (): UseTransfer => {
  const [transferWindow, setTransferWindow] = useState<'general' | 'supervised'>(
    'general'
  );
  const callSelector = useRadio('');
  const search = useField('text');
  const usersList = useSelector(selectUsersList);

  function setGeneralTransferWindow() {
    setTransferWindow('general');
  }

  function setSupervisedTransferWindow() {
    setTransferWindow('supervised');
  }

  const filteredUsers = usersList.filter((user) =>
    user.name.toLowerCase().includes(search.value.toLowerCase())
  );

  const reset = () => {
    setTransferWindow('general');
    callSelector.reset();
    search.reset();
  };

  const callOptions =
    filteredUsers.length === 0
      ? [
          {
            id: 0,
            name: search.value,
          },
        ]
      : filteredUsers;

  return {
    callSelector,
    search,
    callOptions,
    transferWindow,
    setGeneralTransferWindow,
    setSupervisedTransferWindow,
    reset,
  };
};

export default useTransfer;
