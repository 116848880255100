import { createSelector } from '@reduxjs/toolkit';
// Types
import { RootState } from '../../rootReducer';

const selectCalls = (state: RootState) => state.Calls;

export const selectFetchCallLogsStatus = createSelector(
  selectCalls,
  (calls) => calls.status.fetch
);
export const selectCallLogs = createSelector(selectCalls, (calls) => calls.logs);
export const selectFetchExtraLogsStatus = createSelector(
  selectCalls,
  (calls) => calls.status.fetchExtra
);
