// Types
import { CallStatus } from '../../../../../../../../../../../../context/Sip/hooks/useUserAgent/types/CallStatus';
// Components/ui
import { style } from './style';
import { Typography } from '@mui/material';

type CallStatusProps = {
  callStatus: CallStatus;
};
const CallState = ({ callStatus }: CallStatusProps) => {
  return (
    <Typography color={'text.primary'} variant="caption" sx={style.callState}>
      {callStatus === CallStatus.Calling && 'Llamando...'}
      {callStatus === CallStatus.Ringing && 'LLamada entrante'}
      {callStatus === CallStatus.Answered && 'En curso'}
    </Typography>
  );
};

export default CallState;
