// Context
import SipContext from '../../../../../../context/Sip/SipContext';
import { useContext } from 'react';
// Components/ui
import { Avatar, Box, Slide } from '@mui/material';
import { ActionButtons, CallInfo, CloseButton } from './components';
import { style } from './style';

const CallNotification = () => {
  const {
    notification,
    handleAnswerNotificationCall,
    handleRejectNotificationCall,
  } = useContext(SipContext);

  return (
    <Slide in={notification.show} direction="up" mountOnEnter unmountOnExit>
      <Box sx={style.container}>
        <CloseButton handleHide={notification.handleHide} />
        <Avatar sx={style.avatar}>A</Avatar>
        <CallInfo
          number={notification.callInfo?.number}
          name={notification.callInfo?.name}
        />
        <ActionButtons
          handleReject={handleRejectNotificationCall}
          handleAnswer={handleAnswerNotificationCall}
        />
      </Box>
    </Slide>
  );
};

export default CallNotification;
