import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import AppContainer from './AppContainer';
// Types
import RootProps from './types/RootProps';

export default function Root(props: RootProps) {
  console.log("prop", props)
  return (
    <Router>
      <Routes>
        <Route path="/*" Component={() => <AppContainer rootProps={props} />} />
      </Routes>
    </Router>
  );
}
