import React, { useContext } from 'react';
import { ModuleIconsContext } from '../../../../context/ModuleIcons/ModuleIconsContext';
// Components/ui
import { Box, IconButton } from '@mui/material';
const ModuleIcons: React.FC = () => {
  const { enabledIcons, iconsList } = useContext(ModuleIconsContext);

  return (
    <Box display="flex" alignItems="center" mx={2}>
      {iconsList
        .filter((item) => item.enabled)
        .map((item) => (
          <Box key={item.id}>
            <IconButton
              style={{
                opacity: enabledIcons[item.moduleName] ? 1 : 0.5,
                width: '20px',
              }}
              disableRipple={true}
            >
              {item.component}
            </IconButton>
          </Box>
        ))}
    </Box>
  );
};

export default ModuleIcons;
