import { createTheme } from "@mui/material";

//Light themes
export const lightBlueIndigo = createTheme({
  name: "lightBlueIndigo",
  palette: {
    mode: "light",
    background: {
      default: "#ffffff",
      mainCenter: "#ebf6fd",
      mainGradient: "linear-gradient(270deg, #215c97, #1976d2)",
      navMenu: "#1976d2",
      dropdownMenu: "#f5f5f5",
      panel: "#ffffff",
      tooltip: "#1976d2"
    },
    primary: {
      main: "#1976d2",
      light: "#42a5f5",
      dark: "#1565c0",
      contrastText: "#fff",
    },
    secondary: {
      main: "#9c27b0",
      light: "#ba68c8",
      dark: "#7b1fa2",
    },
    error: {
      main: "#d32f2f",
      light: "#ef5350",
      dark: "#c62828",
    },
    warning: {
      main: "#ed6c02",
      light: "#ff9800",
      dark: "#e65100",
    },
    info: {
      main: "#0288d1",
      light: "#03a9f4",
      dark: "#01579b",
    },
    success: {
      main: "#2e7d32",
      light: "#4caf50",
      dark: "#1b5e20",
    },
    containerBox: {
      default: "#ffffff",
      main: "#f5f5f5",
    },
    action: {
      active: "rgba(0, 0, 0, 0.54)",
      hover: "#ebf6fd",
      // hover: "rgsba(0, 0, 0, 0.04)",
      // selected: "rgsba(0, 0, 0, 0.08)",
      selected: "#ebf6fd",
      disabled: "rgba(0, 0, 0, 0.26)",
      disabledBackground: "rgba(255, 255, 255, 0.12)",
    },
    text: {
      primary: "rgba(0, 0, 0, 0.87)",
      secondary: "rgba(0, 0, 0, 0.6)",
      disabled: "rgba(0, 0, 0, 0.38)",
      colorMain: "#1976d2"

    },
    divider: "rgba(0, 0, 0, 0.12)",
    dropdownBox: "#ffffff",
    color: {
      icons: "#1976d2"
    },
    cellHeader: "#d3d3d3",
    Skeleton: {
      bg: "#f5f5f5"
    }
  },
});
export const lightBlue = createTheme({
  name: "lightBlue",
  palette: {
    mode: "light",
    background: {
      default: "#ffffff",
      mainCenter: "#e3f2fd",
      mainGradient: "linear-gradient(270deg, #005cb2, #1e88e5)",
      navMenu: "#4fc354",
      dropdownMenu: "#f5f5f5",
      panel: "#ffffff",
      tooltip: "#1e88e5"
    },
    primary: {
      main: "#1e88e5",
      light: "#6ab7ff",
      dark: "#005cb2",
      contrastText: "#fff",
    },
    secondary: {
      main: "#9c27b0",
      light: "#ba68c8",
      dark: "#7b1fa2",
    },
    error: {
      main: "#d32f2f",
      light: "#ef5350",
      dark: "#c62828",
    },
    warning: {
      main: "#ed6c02",
      light: "#ff9800",
      dark: "#e65100",
    },
    info: {
      main: "#0288d1",
      light: "#03a9f4",
      dark: "#01579b",
    },
    success: {
      main: "#2e7d32",
      light: "#4caf50",
      dark: "#1b5e20",
    },
    containerBox: {
      default: "#ffffff",
      main: "#f5f5f5",
    },
    action: {
      active: "rgba(0, 0, 0, 0.54)",
      hover: "#e3f2fd",
      selected: "#e3f2fd",
      disabled: "rgba(0, 0, 0, 0.26)",
      disabledBackground: "rgba(255, 255, 255, 0.12)",
    },
    text: {
      primary: "rgba(0, 0, 0, 0.87)",
      secondary: "rgba(0, 0, 0, 0.6)",
      disabled: "rgba(0, 0, 0, 0.38)",
      colorMain: "#1e88e5"

    },
    divider: "rgba(0, 0, 0, 0.12)",
    dropdownBox: "#ffffff",
    color: {
      icons: "#1e88e5"
    },
    cellHeader: "#d3d3d3",
    Skeleton: {
      bg: "#f5f5f5"
    }

  },
});

export const lightRed = createTheme({
  name: "lightRed",
  palette: {
    mode: "light",
    background: {
      default: "#ffffff",
      mainCenter: "#ffebee",
      mainGradient: "linear-gradient(270deg, #b61827, #ef5350)",
      navMenu: "#f44336",
      dropdownMenu: "#f5f5f5",
      panel: "#ffffff",
      tooltip: "#ef5350"
    },
    primary: {
      main: "#ef5350",
      light: "#ff867c",
      dark: "#b61827",
      contrastText: "#fff",
    },
    secondary: {
      main: "#9c27b0",
      light: "#ba68c8",
      dark: "#7b1fa2",
    },
    error: {
      main: "#d32f2f",
      light: "#ef5350",
      dark: "#c62828",
    },
    warning: {
      main: "#ed6c02",
      light: "#ff9800",
      dark: "#e65100",
    },
    info: {
      main: "#0288d1",
      light: "#03a9f4",
      dark: "#01579b",
    },
    success: {
      main: "#2e7d32",
      light: "#4caf50",
      dark: "#1b5e20",
    },
    containerBox: {
      default: "#ffffff",
      main: "#f5f5f5",
    },
    action: {
      active: "rgba(0, 0, 0, 0.54)",
      hover: "#ffebee",
      selected: "#ffebee",
      disabled: "rgba(0, 0, 0, 0.26)",
      disabledBackground: "rgba(255, 255, 255, 0.12)",
    },
    text: {
      primary: "rgba(0, 0, 0, 0.87)",
      secondary: "rgba(0, 0, 0, 0.6)",
      disabled: "rgba(0, 0, 0, 0.38)",
      colorMain: "#ef5350"

    },
    divider: "rgba(0, 0, 0, 0.12)",
    dropdownBox: "#ffffff",
    color: {
      icons: "#ef5350"
    },
    cellHeader: "#d3d3d3",
    Skeleton: {
      bg: "#f5f5f5"
    }

  },
});

export const lightGreen = createTheme({
  name: "lightGreen",
  palette: {
    mode: "light",
    background: {
      default: "#ffffff",
      mainCenter: "#e8f5e9",
      mainGradient: "linear-gradient(270deg, #087f23, #4caf50)",
      navMenu: "#4fc354",
      dropdownMenu: "#f5f5f5",
      panel: "#ffffff",
      tooltip: "#4caf50"

    },
    primary: {
      main: "#4caf50",
      light: "#80e27e",
      dark: "#087f23",
      contrastText: "#fff",
    },
    secondary: {
      main: "#9c27b0",
      light: "#ba68c8",
      dark: "#7b1fa2",
    },
    error: {
      main: "#d32f2f",
      light: "#ef5350",
      dark: "#c62828",
    },
    warning: {
      main: "#ed6c02",
      light: "#ff9800",
      dark: "#e65100",
    },
    info: {
      main: "#0288d1",
      light: "#03a9f4",
      dark: "#01579b",
    },
    success: {
      main: "#2e7d32",
      light: "#4caf50",
      dark: "#1b5e20",
    },
    containerBox: {
      default: "#ffffff",
      main: "#f5f5f5",
    },
    action: {
      active: "rgba(0, 0, 0, 0.54)",
      hover: "#e8f5e9",
      selected: "#e8f5e9",
      disabled: "rgba(0, 0, 0, 0.26)",
      disabledBackground: "rgba(255, 255, 255, 0.12)",
    },
    text: {
      primary: "rgba(0, 0, 0, 0.87)",
      secondary: "rgba(0, 0, 0, 0.6)",
      disabled: "rgba(0, 0, 0, 0.38)",
      colorMain: "#4caf50"

    },
    divider: "rgba(0, 0, 0, 0.12)",
    dropdownBox: "#ffffff",
    color: {
      icons: "#4caf50"
    },
    cellHeader: "#d3d3d3",
    Skeleton: {
      bg: "#f5f5f5"
    }

  },
});
export const lightPink = createTheme({
  name: "lightPink",
  palette: {
    mode: "light",
    background: {
      default: "#ffffff",
      mainCenter: "#fce4ec",
      mainGradient: "linear-gradient(270deg, #b0003a, #e91e63)",
      navMenu: "#e91e63",
      dropdownMenu: "#f5f5f5",
      panel: "#ffffff",
      tooltip: "#e91e63"

    },
    primary: {
      main: "#e91e63",
      light: "#ff6090",
      dark: "#b0003a",
      contrastText: "#fff",
    },
    secondary: {
      main: "#9c27b0",
      light: "#ba68c8",
      dark: "#7b1fa2",
    },
    error: {
      main: "#d32f2f",
      light: "#ef5350",
      dark: "#c62828",
    },
    warning: {
      main: "#ed6c02",
      light: "#ff9800",
      dark: "#e65100",
    },
    info: {
      main: "#0288d1",
      light: "#03a9f4",
      dark: "#01579b",
    },
    success: {
      main: "#2e7d32",
      light: "#4caf50",
      dark: "#1b5e20",
    },
    containerBox: {
      default: "#ffffff",
      main: "#f5f5f5",
    },
    action: {
      active: "rgba(0, 0, 0, 0.54)",
      hover: "#fce4ec",
      selected: "#fce4ec",
      disabled: "rgba(0, 0, 0, 0.26)",
      disabledBackground: "rgba(255, 255, 255, 0.12)",
    },
    text: {
      primary: "rgba(0, 0, 0, 0.87)",
      secondary: "rgba(0, 0, 0, 0.6)",
      disabled: "rgba(0, 0, 0, 0.38)",
      colorMain: "#e91e63"

    },
    divider: "rgba(0, 0, 0, 0.12)",
    dropdownBox: "#ffffff",
    color: {
      icons: "#e91e63"
    },
    cellHeader: "#d3d3d3",
    Skeleton: {
      bg: "#f5f5f5"
    }

  },
});
export const lightPurple = createTheme({
  name: "lightPurple",
  palette: {
    mode: "light",
    background: {
      default: "#ffffff",
      mainCenter: "#f3e5f5",
      mainGradient: "linear-gradient(270deg, #6a0080, #9c27b0)",
      navMenu: "#f8bbd0",
      dropdownMenu: "#f5f5f5",
      panel: "#ffffff",
      tooltip: "#9c27b0"

    },
    primary: {
      main: "#9c27b0",
      light: "#d05ce3",
      dark: "#6a0080",
      contrastText: "#fff",
    },
    secondary: {
      main: "#9c27b0",
      light: "#ba68c8",
      dark: "#7b1fa2",
    },
    error: {
      main: "#d32f2f",
      light: "#ef5350",
      dark: "#c62828",
    },
    warning: {
      main: "#ed6c02",
      light: "#ff9800",
      dark: "#e65100",
    },
    info: {
      main: "#0288d1",
      light: "#03a9f4",
      dark: "#01579b",
    },
    success: {
      main: "#2e7d32",
      light: "#4caf50",
      dark: "#1b5e20",
    },
    containerBox: {
      default: "#ffffff",
      main: "#f5f5f5",
    },
    action: {
      active: "rgba(0, 0, 0, 0.54)",
      hover: "#f3e5f5",
      selected: "#f3e5f5",
      disabled: "rgba(0, 0, 0, 0.26)",
      disabledBackground: "rgba(255, 255, 255, 0.12)",
    },
    text: {
      primary: "rgba(0, 0, 0, 0.87)",
      secondary: "rgba(0, 0, 0, 0.6)",
      disabled: "rgba(0, 0, 0, 0.38)",
      colorMain: "#9c27b0"
    },
    divider: "rgba(0, 0, 0, 0.12)",
    dropdownBox: "#ffffff",
    color: {
      icons: "#9c27b0"
    },
    cellHeader: "#d3d3d3",
    Skeleton: {
      bg: "#f5f5f5"
    }

  },
});
export const lightTeal = createTheme({
  name: "lightTeal",
  palette: {
    mode: "light",
    background: {
      default: "#ffffff",
      mainCenter: "#e0f2f1",
      mainGradient: "linear-gradient(270deg, #00675b, #009688)",
      navMenu: "#f8bbd0",
      dropdownMenu: "#f5f5f5",
      panel: "#ffffff",
      tooltip: "#009688"

    },
    primary: {
      main: "#009688",
      light: "#52c7b8",
      dark: "#00675b",
      contrastText: "#fff",
    },
    secondary: {
      main: "#9c27b0",
      light: "#ba68c8",
      dark: "#7b1fa2",
    },
    error: {
      main: "#d32f2f",
      light: "#ef5350",
      dark: "#c62828",
    },
    warning: {
      main: "#ed6c02",
      light: "#ff9800",
      dark: "#e65100",
    },
    info: {
      main: "#0288d1",
      light: "#03a9f4",
      dark: "#01579b",
    },
    success: {
      main: "#2e7d32",
      light: "#4caf50",
      dark: "#1b5e20",
    },
    containerBox: {
      default: "#ffffff",
      main: "#f5f5f5",
    },
    action: {
      active: "rgba(0, 0, 0, 0.54)",
      hover: "#e0f2f1",
      selected: "#e0f2f1",
      disabled: "rgba(0, 0, 0, 0.26)",
      disabledBackground: "rgba(255, 255, 255, 0.12)",
    },
    text: {
      primary: "rgba(0, 0, 0, 0.87)",
      secondary: "rgba(0, 0, 0, 0.6)",
      disabled: "rgba(0, 0, 0, 0.38)",
      colorMain: "#009688"

    },
    divider: "rgba(0, 0, 0, 0.12)",
    dropdownBox: "#ffffff",
    color: {
      icons: "#009688"
    },
    cellHeader: "#d3d3d3",
    Skeleton: {
      bg: "#f5f5f5"
    }

  },
});
export const lightLime = createTheme({
  name: "lightLime",
  palette: {
    mode: "light",
    background: {
      default: "#ffffff",
      mainCenter: "#f9fbe7",
      mainGradient: "linear-gradient(270deg, #99aa00, #cddc39)",
      navMenu: "#f8bbd0",
      dropdownMenu: "#f5f5f5",
      panel: "#ffffff",
      tooltip: "#cddc39"

    },
    primary: {
      main: "#cddc39",
      light: "#ffff6e",
      dark: "#99aa00",
      contrastText: "#fff",
    },
    secondary: {
      main: "#9c27b0",
      light: "#ba68c8",
      dark: "#7b1fa2",
    },
    error: {
      main: "#d32f2f",
      light: "#ef5350",
      dark: "#c62828",
    },
    warning: {
      main: "#ed6c02",
      light: "#ff9800",
      dark: "#e65100",
    },
    info: {
      main: "#0288d1",
      light: "#03a9f4",
      dark: "#01579b",
    },
    success: {
      main: "#2e7d32",
      light: "#4caf50",
      dark: "#1b5e20",
    },
    containerBox: {
      default: "#ffffff",
      main: "#f5f5f5",
    },
    action: {
      active: "rgba(0, 0, 0, 0.54)",
      hover: "#f9fbe7",
      selected: "#f9fbe7",
      disabled: "rgba(0, 0, 0, 0.26)",
      disabledBackground: "rgba(255, 255, 255, 0.12)",
    },
    text: {
      primary: "rgba(0, 0, 0, 0.87)",
      secondary: "rgba(0, 0, 0, 0.6)",
      disabled: "rgba(0, 0, 0, 0.38)",
      colorMain: "#cddc39"

    },
    divider: "rgba(0, 0, 0, 0.12)",
    dropdownBox: "#ffffff",
    color: {
      icons: "#cddc39"
    },
    cellHeader: "#d3d3d3",
    Skeleton: {
      bg: "#f5f5f5"
    }

  },
});
export const lightYellow = createTheme({
  name: "lightYellow",
  palette: {
    mode: "light",
    background: {
      default: "#ffffff",
      mainCenter: "#fffde7",
      mainGradient: "linear-gradient(270deg, #c8b900, #ffeb3b)",
      navMenu: "#f8bbd0",
      dropdownMenu: "#f5f5f5",
      panel: "#ffffff",
      tooltip: "#ffeb3b"

    },
    primary: {
      main: "#ffeb3b",
      light: "#ffff72",
      dark: "#c8b900",
      contrastText: "#fff",
    },
    secondary: {
      main: "#9c27b0",
      light: "#ba68c8",
      dark: "#7b1fa2",
    },
    error: {
      main: "#d32f2f",
      light: "#ef5350",
      dark: "#c62828",
    },
    warning: {
      main: "#ed6c02",
      light: "#ff9800",
      dark: "#e65100",
    },
    info: {
      main: "#0288d1",
      light: "#03a9f4",
      dark: "#01579b",
    },
    success: {
      main: "#2e7d32",
      light: "#4caf50",
      dark: "#1b5e20",
    },
    containerBox: {
      default: "#ffffff",
      main: "#f5f5f5",
    },
    action: {
      active: "rgba(0, 0, 0, 0.54)",
      hover: "#fffde7",
      selected: "#fffde7",
      disabled: "rgba(0, 0, 0, 0.26)",
      disabledBackground: "rgba(255, 255, 255, 0.12)",
    },
    text: {
      primary: "rgba(0, 0, 0, 0.87)",
      secondary: "rgba(0, 0, 0, 0.6)",
      disabled: "rgba(0, 0, 0, 0.38)",
      colorMain: "#ffeb3b"

    },
    divider: "rgba(0, 0, 0, 0.12)",
    dropdownBox: "#ffffff",
    color: {
      icons: "#ffeb3b"
    },
    cellHeader: "#d3d3d3",
    Skeleton: {
      bg: "#f5f5f5"
    }

  },
});
export const lightAmber = createTheme({
  name: "lightAmber",
  palette: {
    mode: "light",
    background: {
      default: "#ffffff",
      mainCenter: "#fff8e1",
      mainGradient: "linear-gradient(270deg, #c68400, #ffb300)",
      navMenu: "#f8bbd0",
      dropdownMenu: "#f5f5f5",
      panel: "#ffffff",
      tooltip: "#ffb300"

    },
    primary: {
      main: "#ffb300",
      light: "#ffe54c",
      dark: "#c68400",
      contrastText: "#fff",
    },
    secondary: {
      main: "#9c27b0",
      light: "#ba68c8",
      dark: "#7b1fa2",
    },
    error: {
      main: "#d32f2f",
      light: "#ef5350",
      dark: "#c62828",
    },
    warning: {
      main: "#ed6c02",
      light: "#ff9800",
      dark: "#e65100",
    },
    info: {
      main: "#0288d1",
      light: "#03a9f4",
      dark: "#01579b",
    },
    success: {
      main: "#2e7d32",
      light: "#4caf50",
      dark: "#1b5e20",
    },
    containerBox: {
      default: "#ffffff",
      main: "#f5f5f5",
    },
    action: {
      active: "rgba(0, 0, 0, 0.54)",
      hover: "#fff8e1",
      selected: "#fff8e1",
      disabled: "rgba(0, 0, 0, 0.26)",
      disabledBackground: "rgba(255, 255, 255, 0.12)",
    },
    text: {
      primary: "rgba(0, 0, 0, 0.87)",
      secondary: "rgba(0, 0, 0, 0.6)",
      disabled: "rgba(0, 0, 0, 0.38)",
      colorMain: "#ffb300"

    },
    divider: "rgba(0, 0, 0, 0.12)",
    dropdownBox: "#ffffff",
    color: {
      icons: "#ffb300"
    },
    cellHeader: "#d3d3d3",
    Skeleton: {
      bg: "#f5f5f5"
    }

  },
});
export const lightOrange = createTheme({
  name: "lightOrange",
  palette: {
    mode: "light",
    background: {
      default: "#ffffff",
      mainCenter: "#fff3e0",
      mainGradient: "linear-gradient(270deg, #c66900, #ff9800)",
      navMenu: "#f8bbd0",
      dropdownMenu: "#f5f5f5",
      panel: "#ffffff",
      tooltip: "#ff9800"

    },
    primary: {
      main: "#ff9800",
      light: "#ffc947",
      dark: "#c66900",
      contrastText: "#fff",
    },
    secondary: {
      main: "#9c27b0",
      light: "#ba68c8",
      dark: "#7b1fa2",
    },
    error: {
      main: "#d32f2f",
      light: "#ef5350",
      dark: "#c62828",
    },
    warning: {
      main: "#ed6c02",
      light: "#ff9800",
      dark: "#e65100",
    },
    info: {
      main: "#0288d1",
      light: "#03a9f4",
      dark: "#01579b",
    },
    success: {
      main: "#2e7d32",
      light: "#4caf50",
      dark: "#1b5e20",
    },
    containerBox: {
      default: "#ffffff",
      main: "#f5f5f5",
    },
    action: {
      active: "rgba(0, 0, 0, 0.54)",
      hover: "#fff3e0",
      selected: "#fff3e0",
      disabled: "rgba(0, 0, 0, 0.26)",
      disabledBackground: "rgba(255, 255, 255, 0.12)",
    },
    text: {
      primary: "rgba(0, 0, 0, 0.87)",
      secondary: "rgba(0, 0, 0, 0.6)",
      disabled: "rgba(0, 0, 0, 0.38)",
      colorMain: "#ff9800"
    },
    divider: "rgba(0, 0, 0, 0.12)",
    dropdownBox: "#ffffff",
    color: {
      icons: "#ff9800"
    },
    cellHeader: "#d3d3d3",
    Skeleton: {
      bg: "#f5f5f5"
    }

  },
});
export const lightBrown = createTheme({
  name: "lightBrown",
  palette: {
    mode: "light",
    background: {
      default: "#ffffff",
      mainCenter: "#efebe9",
      mainGradient: "linear-gradient(270deg, #4b2c20, #795548)",
      navMenu: "#f8bbd0",
      dropdownMenu: "#f5f5f5",
      panel: "#ffffff",
      tooltip: "#795548"

    },
    primary: {
      main: "#795548",
      light: "#a98274",
      dark: "#4b2c20",
      contrastText: "#fff",
    },
    secondary: {
      main: "#9c27b0",
      light: "#ba68c8",
      dark: "#7b1fa2",
    },
    error: {
      main: "#d32f2f",
      light: "#ef5350",
      dark: "#c62828",
    },
    warning: {
      main: "#ed6c02",
      light: "#ff9800",
      dark: "#e65100",
    },
    info: {
      main: "#0288d1",
      light: "#03a9f4",
      dark: "#01579b",
    },
    success: {
      main: "#2e7d32",
      light: "#4caf50",
      dark: "#1b5e20",
    },
    containerBox: {
      default: "#ffffff",
      main: "#f5f5f5",
    },
    action: {
      active: "rgba(0, 0, 0, 0.54)",
      hover: "#efebe9",
      selected: "#efebe9",
      disabled: "rgba(0, 0, 0, 0.26)",
      disabledBackground: "rgba(255, 255, 255, 0.12)",
    },
    text: {
      primary: "rgba(0, 0, 0, 0.87)",
      secondary: "rgba(0, 0, 0, 0.6)",
      disabled: "rgba(0, 0, 0, 0.38)",
      colorMain: "#795548"
    },
    divider: "rgba(0, 0, 0, 0.12)",
    dropdownBox: "#ffffff",
    color: {
      icons: "#795548"
    },
    cellHeader: "#d3d3d3",
    Skeleton: {
      bg: "#f5f5f5"
    }

  },
});
export const lightGray = createTheme({
  name: "lightGray",
  palette: {
    mode: "light",
    background: {
      default: "#ffffff",
      mainCenter: "#fafafa",
      mainGradient: "linear-gradient(270deg, #707070, #9e9e9e)",
      navMenu: "#f8bbd0",
      dropdownMenu: "#f5f5f5",
      panel: "#ffffff",
      tooltip: "#9e9e9e"

    },
    primary: {
      main: "#9e9e9e",
      light: "#cfcfcf",
      dark: "#707070",
      contrastText: "#fff",
    },
    secondary: {
      main: "#9c27b0",
      light: "#ba68c8",
      dark: "#7b1fa2",
    },
    error: {
      main: "#d32f2f",
      light: "#ef5350",
      dark: "#c62828",
    },
    warning: {
      main: "#ed6c02",
      light: "#ff9800",
      dark: "#e65100",
    },
    info: {
      main: "#0288d1",
      light: "#03a9f4",
      dark: "#01579b",
    },
    success: {
      main: "#2e7d32",
      light: "#4caf50",
      dark: "#1b5e20",
    },
    containerBox: {
      default: "#ffffff",
      main: "#f5f5f5",
    },
    action: {
      active: "rgba(0, 0, 0, 0.54)",
      hover: "#fafafa",
      selected: "#fafafa",
      disabled: "rgba(0, 0, 0, 0.26)",
      disabledBackground: "rgba(255, 255, 255, 0.12)",
    },
    text: {
      primary: "rgba(0, 0, 0, 0.87)",
      secondary: "rgba(0, 0, 0, 0.6)",
      disabled: "rgba(0, 0, 0, 0.38)",
      colorMain: "#9e9e9e"
    },
    divider: "rgba(0, 0, 0, 0.12)",
    dropdownBox: "#ffffff",
    color: {
      icons: "#9e9e9e"
    },
    cellHeader: "#d3d3d3",
    Skeleton: {
      bg: "#f5f5f5"
    }

  },
});




//Dark Themes
export const darkBlue = createTheme({
  name: "darkBlue",
  palette: {
    mode: "dark",
    primary: {
      main: "#90caf9",
      light: "#2196f3",
      dark: "#0d47a1",
      contrastText: "#fff",
    },
    secondary: {
      light: "#FFEA41",
      main: "#FFE102",
      dark: "#DBBE01",
      contrastText: "rgba(0, 0, 0, 0.87)",
    },

    error: {
      main: "#f44336",
      light: "#e57373",
      dark: "#d32f2f",
    },
    warning: {
      main: "#ffa726",
      light: "#ffb74d",
      dark: "#f57c00",
    },
    info: {
      main: "#29b6f6",
      light: "#4fc3f7",
      dark: "#0288d1",
    },
    success: {
      main: "#66bb6a",
      light: "#81c784",
      dark: "#388e3c",
    },

    background: {
      default: "#222B45",
      mainGradient: "linear-gradient(270deg, #222B45, #222B45)",
      mainCenter: "#28324e",
      navMenu: "#222B45",
      panel: "#2d364e",
      dropdownMenu: "#484f65",
      tooltip: "#484f65"
    },

    containerBox: {
      default: "#2d354e",
      main: "#2d354e",
    },
    action: {
      active: "#fff",
      hover: "rgba(255, 255, 255, 0.08)",
      selected: "rgba(255, 255, 255, 0.16)",
      disabled: "rgba(255, 255, 255, 0.3)",
      disabledBackground: "rgba(255, 255, 255, 0.12)",
    },
    text: {
      primary: "#EEEEEF",
      secondary: "#AEB0B4",
      disabled: "rgba(255, 255, 255, 0.5)",
      colorMain: "#EEEEEF"
    },
    divider: "rgba(255, 255, 255, 0.12)",
    dropdownBox: "#2d354e",
    color: {
      icons: "#fff"
    },
    cellHeader: "#505050",
    Skeleton: {
      bg: "#f5f5f5"
    }
  },
});
export const darkBlack = createTheme({
  name: "darkBlack",
  palette: {
    mode: "dark",
    background: {
      default: "#201f1e",
      mainGradient: "linear-gradient(270deg, #201f1e, #201f1e)",
      mainCenter: "#252423",
      navMenu: "#201f1e",
      panel: "#3B3A39",
      dropdownMenu: "#585858",
      tooltip: "#616161eb",
    },
    primary: {
      main: "#1976d2",
      light: "#e3f2fd",
      dark: "#42a5f5",
      contrastText: "#fff",
    },
    secondary: {
      main: "#ce93d8",
      light: "#f3e5f5",
      dark: "#ab47bc",
      contrastText: "rgba(0, 0, 0, 0.87)",
    },
    error: {
      main: "#f44336",
      light: "#e57373",
      dark: "#d32f2f",
    },
    warning: {
      main: "#ffa726",
      light: "#ffb74d",
      dark: "#f57c00",
    },
    info: {
      main: "#29b6f6",
      light: "#4fc3f7",
      dark: "#0288d1",
    },
    success: {
      main: "#66bb6a",
      light: "#81c784",
      dark: "#388e3c",
    },
    containerBox: {
      main: "#ffffffb3",
      default: "#3B3A39",
    },
    action: {
      active: "#fff",
      hover: "rgba(255, 255, 255, 0.08)",
      selected: "rgba(255, 255, 255, 0.16)",
      disabled: "rgba(255, 255, 255, 0.3)",
      disabledBackground: "rgba(255, 255, 255, 0.12)",
    },
    text: {
      primary: "#EEEEEF",
      secondary: "#AEB0B4",
      disabled: "rgba(255, 255, 255, 0.5)",
      colorMain: "#EEEEEF"
    },
    divider: "rgba(255, 255, 255, 0.12)",
    dropdownBox: "#3B3A39",
    color: {
      icons: "#fff"
    },
    cellHeader: "#505050",
    Skeleton: {
      bg: "#f5f5f5"
    }
  },
});
  