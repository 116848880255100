import { useTranslation } from 'react-i18next';
// Components/ui
import { TextField, InputAdornment, Box, LinearProgress } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { style } from './style';

type SearchInputProps = {
  isLoading: boolean;
  search: string;
  handleChange: (value: string) => void;
};
const SearchInput = ({ isLoading, search, handleChange }: SearchInputProps) => {
  const { t } = useTranslation();
  return (
    <Box sx={style.container}>
      {isLoading && <LinearProgress sx={style.loader} />}
      <TextField
        placeholder={`${t('calls.search')}...`}
        fullWidth
        size="small"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon
                fontSize="small"
                sx={{
                  color: (theme) => theme.palette.text.disabled,
                }}
              />
            </InputAdornment>
          ),
        }}
        value={search}
        onChange={(e) => handleChange(e.target.value)}
      />
    </Box>
  );
};

export default SearchInput;
