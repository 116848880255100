// Context
import { useContext, useEffect, useState } from 'react';
import SipContext from '../../../../../../../../../../context/Sip/SipContext';
// Components
import { Box, Typography } from '@mui/material';
import {
  AnsweredActionButtons,
  CallDataDisplay,
  CallState,
  CallingActionButtons,
  RingingActionButtons,
  TransferCallWindow,
} from './components';
import { style } from './style';
// Types
import { CallStatus } from '../../../../../../../../../../context/Sip/hooks/useUserAgent/types/CallStatus';
import { DialPad, DialVisor } from '../../layout';

const Call = () => {
  const {
    callTimer: { time },
    dial: { dialValue, handleChange },
    userAgent: {
      callStatus,
      endCall,
      acceptCall,
      hold,
      holdStatus,
      unhold,
      inviterInfo,
      mute,
      muteStatus,
      unmute,
    },
    callWindow,
    handleDialPress,
  } = useContext(SipContext);

  return (
    <Box sx={style.container}>
      <CallState callStatus={callStatus} />
      <TransferCallWindow isOpen={callWindow.transferCallWindowOpen} />
      {callWindow.callDialerOpen && (
        <>
          {callStatus === CallStatus.Answered && (
            <DialVisor value={dialValue} handleChange={handleChange} />
          )}
          <Typography color={'text.primary'} sx={style.callNumber}>
            {callStatus === CallStatus.Calling && dialValue}
            {callStatus === CallStatus.Ringing && (
              <CallDataDisplay data={inviterInfo} />
            )}
          </Typography>
          <Typography color={'text.secondary'} sx={style.timer}>
            {callStatus === CallStatus.Answered ? time : ''}
          </Typography>
          {callStatus === CallStatus.Answered && (
            <DialPad handleDial={handleDialPress} />
          )}
          {callStatus === CallStatus.Ringing && (
            <RingingActionButtons acceptCall={acceptCall} rejectCall={endCall} />
          )}
          {callStatus === CallStatus.Calling && (
            <CallingActionButtons rejectCall={endCall} />
          )}
          {callStatus === CallStatus.Answered && (
            <AnsweredActionButtons
              endCall={endCall}
              handleOpenTransferCallWindow={callWindow.handleOpenTransferCallWindow}
              holdStatus={holdStatus}
              hold={hold}
              unhold={unhold}
              mute={mute}
              muteStatus={muteStatus}
              unmute={unmute}
            />
          )}
        </>
      )}
    </Box>
  );
};

export default Call;
