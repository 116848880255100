import { useEffect, useState } from 'react';
// Components/ui
import { Box } from '@mui/material';
import { slideIn, slideOut, style } from './style';
import { NumberItem } from './components';
import { IContactAddress } from '@trii/types/dist/Contacts';

type NumbersBoxProps = {
  open: boolean;
  contactPhones: IContactAddress[];
};
const NumbersBox = ({ open, contactPhones }: NumbersBoxProps) => {
  const [showNumbers, setShowNumbers] = useState(false);
  const amountOfNumbers = contactPhones.length;

  useEffect(() => {
    open &&
      setTimeout(() => {
        setShowNumbers(true);
      }, 100);
  }, [open]);
  return (
    <Box
      sx={{
        ...style.container,
        animation: `${
          open ? slideIn(amountOfNumbers) : slideOut(amountOfNumbers)
        } 0.15s ease-out forwards`,
      }}
    >
      {showNumbers &&
        contactPhones.map((phone) => (
          <NumberItem key={phone.id} number={phone.address} />
        ))}
    </Box>
  );
};

export default NumbersBox;
