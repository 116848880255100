import { useEffect, useRef } from 'react';
// Components/ui
import { Box } from '@mui/material';
import {
  CloseButton,
  DrawerTabs,
  ContactsPanel,
  CallsHistoryPanel,
  MessagesPanel,
  ConnectionInfo,
  CallPanel,
} from './components';
import { style, slideIn, slideOut } from './style';

type DrawerProps = {
  open: boolean;
  onClose: () => void;
  handleDrawerTabChange: (event: React.ChangeEvent<{}>, newValue: number) => void;
  tabValue: number;
};

const Drawer = ({ open, onClose, handleDrawerTabChange, tabValue }: DrawerProps) => {
  const ref = useRef(null);
  const initialIsPinned = localStorage.getItem('callPopupIsPinned') === 'true'; 

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const callPopupIsPinned = localStorage.getItem('callPopupIsPinned') === 'true';
      if (!callPopupIsPinned) {
        if (ref.current && !ref.current.contains(event.target)) {
          onClose();
        }
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onClose]);

  return (
    <Box
      ref={ref}
      sx={{
        ...style.container,
        animation: `${open ? slideIn : slideOut} 0.15s ease-out forwards`,
      }}
    >
      <CloseButton onClick={onClose} />
      <ConnectionInfo />
      <DrawerTabs handleChange={handleDrawerTabChange} value={tabValue} />
      <CallPanel value={tabValue} />
      <ContactsPanel value={tabValue} />
      <CallsHistoryPanel value={tabValue} />
      <MessagesPanel value={tabValue} />
    </Box>
  );
};

export default Drawer;
