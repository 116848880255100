// Components/ui
import { PhoneTabPanel } from '../../layout';
import { MessageItem, VoiceMailCall } from './components';

type MessagesPanelProps = {
  value: number;
};

const MessagesPanel = ({ value }: MessagesPanelProps) => {
  return (
    <PhoneTabPanel index={3} value={value}>
      <VoiceMailCall />
    </PhoneTabPanel>
  );
};

export default MessagesPanel;
