// Components/ui
import { Box, Typography, IconButton } from '@mui/material';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import { style } from './style';
// Context
import { useContext } from 'react';
import SipContext from '../../../../../../../../../../../../../../../../context/Sip/SipContext';

type NumberItemProps = {
  number: string;
};

const NumberItem = ({ number }: NumberItemProps) => {
  const { handleMakeCallFromExternal } = useContext(SipContext);
  return (
    <Box sx={style.container}>
      <Typography color={'text.primary'} variant="caption">
        {number}
      </Typography>
      <IconButton onClick={() => handleMakeCallFromExternal(number)}>
        <LocalPhoneIcon sx={{ height: '13px', width: '13px' }} />
      </IconButton>
    </Box>
  );
};

export default NumberItem;
