import { useEffect, useRef, useState } from 'react';
import * as SIP from 'sip.js';
// Redux
import { selectUsersList } from '../../../../redux/features/usersSlice/selectors';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../../../hooks/useAppDispatch';
// Helper
import {
  onCallHold,
  onMessage,
  onNotify,
  onRefer,
  onReferRequest,
  onRegister,
  onRegisterRequest,
  onStateChange,
  onSubscribe,
  onSubscribeRequest,
  onAcceptHold,
  onRejectHold,
  getExtraHeaders,
} from '../../sipHelper';
// Types
import { UseUserAgent } from './useUserAgent.type';
import { CallStatus } from './types/CallStatus';
import { fetchContacts } from '../../../../redux/features/contactsSlice/contactsSlice';
import { fetchUsers } from '../../../../redux/features/usersSlice/usersSlice';
import { CallInfo } from '../useCallNotification/useCallNotification.type';

const useUserAgent = (
  extension: string,
  password: string,
  sipDomain: string
): UseUserAgent => {
  const dispatch = useAppDispatch();

  const onDisconnectRef = useRef(onDisconnect);
  const manuallyStoppedRef = useRef(false);
  const userAgentRef = useRef<SIP.UserAgent | null>(null);

  const [transportStatus, setTransportStatus] = useState<
    'connected' | 'disconnected'
  >('disconnected');
  const [inviterInfo, setInviterInfo] = useState<CallInfo | null>(null);
  const [userAgent, setUserAgent] = useState<SIP.UserAgent | null>(null);
  const [config, setConfig] = useState(null);
  const [session, setSession] = useState(null);
  const [transferSession, setTransferSession] = useState<SIP.Inviter>(null);
  const [manuallyStopped, setManuallyStoped] = useState(false);
  const [callStatus, setCallStatus] = useState<CallStatus>(CallStatus.Idle);
  const [transferCallStatus, setTransferCallStatus] = useState<CallStatus>(
    CallStatus.Idle
  );
  const [holdStatus, setHoldStatus] = useState<boolean>(false);
  const [muteStatus, setMuteStatus] = useState<boolean>(false);

  const audioOut = document.getElementById('audio-out') as HTMLMediaElement;

  const users = useSelector(selectUsersList);

  let reconnectIntervalId = null;
  let reconnectStartTime = null;
  // Call functions
  async function makeCall(number: string) {
    try {
      setCallStatus(CallStatus.Calling);

      const target = SIP.UserAgent.makeURI(`sip:${number}@${sipDomain}`);
      console.log('userAgent', userAgentRef.current);

      const session = new SIP.Inviter(userAgentRef.current, target);
      setSession(session);

      await session.invite();

      session.stateChange.addListener((newState) => {
        console.log('session change: ' + newState);
        switch (newState) {
          case SIP.SessionState.Establishing:
            setCallStatus(CallStatus.Answered);
            break;
          case SIP.SessionState.Established:
            setCallStatus(CallStatus.Answered);
            setupRemoteMedia(session);
            break;
          case SIP.SessionState.Terminated:
            setCallStatus(CallStatus.Idle);
            setSession(null);
            break;
          default:
            break;
        }
      });
    } catch (e) {
      console.error('ERROR: ', e);
    }
  }
  async function endCall() {
    if (session === null) return;

    switch (session.state) {
      case SIP.SessionState.Initial:
      case SIP.SessionState.Establishing: {
        if (session instanceof SIP.Inviter) {
          // An unestablished outgoing session
          await session.cancel();
        } else {
          // An unestablished incoming session
          await session.reject();
        }
        break;
      }
      case SIP.SessionState.Established:
        // An established session
        await session.bye();
        break;
      case SIP.SessionState.Terminating:
      case SIP.SessionState.Terminated:
        // Cannot terminate a session that is already terminated
        break;
    }
    setCallStatus(CallStatus.Idle);
  }
  async function endTransferCall() {
    if (transferSession === null) return;

    switch (transferSession.state) {
      case SIP.SessionState.Initial:
      case SIP.SessionState.Establishing: {
        if (transferSession instanceof SIP.Inviter) {
          // An unestablished outgoing session
          await transferSession.cancel();
        } else {
          // An unestablished incoming session
          // @ts-ignore
          await transferSession.reject();
        }
        break;
      }
      case SIP.SessionState.Established:
        // An established session
        await transferSession.bye();
        break;
      case SIP.SessionState.Terminating:
      case SIP.SessionState.Terminated:
        // Cannot terminate a session that is already terminated
        break;
    }
    setTransferCallStatus(CallStatus.Idle);
  }
  function acceptCall() {
    try {
      if (session != null) {
        const options = {
          // Definir eventos en las opciones de session.accept()
          onProgress: function () {
            console.log('Llamada en progreso');
          },
          onAccepted: function () {
            console.log('Llamada aceptada');
          },
          onEnded: function () {
            console.log('Llamada finalizada');
          },
        };

        session.accept(options);
        setCallStatus(CallStatus.Answered);
      }
    } catch {}
  }
  function blindTransferCall(transferNumber: string) {
    const sessionAsInviter = session as SIP.Inviter;

    let target = SIP.UserAgent.makeURI(`sip:${transferNumber}@${sipDomain}`);
    sessionAsInviter.refer(target);
  }
  function attendedTransferCall(transferNumber: string) {
    const sessionAsInviter = session as SIP.Inviter;

    if (!sessionAsInviter) {
      console.error('No active session to transfer.');
      return;
    }

    const target = SIP.UserAgent.makeURI(`sip:${transferNumber}@${sipDomain}`);
    const newSession = new SIP.Inviter(userAgent, target);

    newSession.stateChange.addListener(async (newState) => {
      switch (newState) {
        case SIP.SessionState.Establishing:
          console.log('Attended transfer: new call is establishing...');
          setTransferSession(newSession);
          break;
        case SIP.SessionState.Established:
          console.log('Attended transfer: new call established.');
          setTransferCallStatus(CallStatus.Answered);
          break;
        case SIP.SessionState.Terminated:
          setTransferCallStatus(CallStatus.Idle);
          setTransferSession(null);
          break;
        default:
          break;
      }
    });

    // Start the new session (call)
    newSession.invite().catch((error) => {
      console.error('Failed to start the new session for attended transfer.', error);
    });
  }

  async function completeAttendedTransfer() {
    // Perform the attended transfer by referring the original session to the new session
    if (transferSession && session) {
      try {
        await session.refer(transferSession);
        console.log('Attended transfer: transfer successful.');

        if (session) {
          await session.bye();
        }

        setCallStatus(CallStatus.Idle);
        setTransferCallStatus(CallStatus.Idle);
        setTransferSession(null);
      } catch (error) {
        console.error('Attended transfer: transfer failed.', error);
      }
    }
  }

  function hold() {
    try {
      if (session !== null) {
        let options = {
          sessionDescriptionHandlerOptions: {
            hold: true,
            delegate: {
              onAccept: onAcceptHold,
              onReject: onRejectHold,
              progress: onRejectHold,
            },
            sessionDescriptionHandlerOptions: {
              constraints: {
                audio: true,
                video: false,
              },
            },
            extraHeaders: getExtraHeaders(session),
          },
          onInfo: function (request) {
            console.log('INFO recibido');
          },
          delegate: {
            onAccept: onAcceptHold,
            onReject: onRejectHold,
          },
          onAccept: onAcceptHold,
          onReject: onRejectHold,
          progress: onRejectHold,
        };

        session.invite(options);
        setHoldStatus(true);
      }
    } catch (e) {
      console.log('e', e);
    }
  }
  function unhold() {
    try {
      if (session !== null) {
        let options = {
          sessionDescriptionHandlerOptions: {
            hold: false,
            sessionDescriptionHandlerOptions: {
              constraints: {
                audio: true,
                video: false,
              },
            },
            extraHeaders: getExtraHeaders(session),
          },
          onInfo: function (request) {
            console.log('INFO recibido');
          },
        };
        session.invite(options);

        setHoldStatus(false);
      }
    } catch (e) {
      console.log('e', e);
    }
  }
  function mute() {
    const audioTrack = getAudioTrack();
    if (audioTrack) {
      audioTrack.enabled = false;
      setMuteStatus(true);
    }
  }
  function unmute() {
    const audioTrack = getAudioTrack();
    if (audioTrack) {
      audioTrack.enabled = true;
      setMuteStatus(false);
    }
  }
  // DTMF
  function sendDTMF(tone: string) {
    const options = {
      requestOptions: {
        body: {
          contentDisposition: 'render',
          contentType: 'application/dtmf-relay',
          content: 'Signal=' + tone + '\r\nDuration=1000',
        },
      },
    };

    session.info(options);
  }
  // Audio media
  function setupRemoteMedia(session) {
    if (session === null) return;

    const remoteStream = new MediaStream();

    session?.sessionDescriptionHandler?.peerConnection
      .getReceivers()
      .forEach((receiver) => {
        if (receiver.track) {
          remoteStream.addTrack(receiver.track);
        }
      });

    audioOut.srcObject = remoteStream;
    audioOut.play();
  }
  function getAudioTrack() {
    if (session) {
      const mediaStream =
        session?.sessionDescriptionHandler?.peerConnection?.getLocalStreams()[0];

      if (mediaStream) {
        const audioTracks = mediaStream.getAudioTracks();
        if (audioTracks.length > 0) {
          return audioTracks[0];
        }
      }
    }
    return null;
  }

  // Config helpers
  function generateUri(username: string): SIP.URI {
    return SIP.UserAgent.makeURI(`sip:${username}@${sipDomain}`);
  }
  function generateConfig(username: string, password: string): SIP.UserAgentOptions {
    let uri = generateUri(username);
    return {
      uri: uri,
      viaHost: sipDomain,
      authorizationPassword: password,
      authorizationUsername: username,
      transportOptions: {
        server: 'wss://sip-webrtc.trii.app:7443/ws',
      },
      sessionDescriptionHandlerFactoryOptions: {
        peerConnectionOptions: {
          iceServers: [
            //{ urls: 'stun:stun.l.google.com:19302' }
          ],
        },
      },
      iceServers: [
        //{ urls: 'stun:stun.l.google.com:19302' }
      ],
      logBuiltinEnabled: false,
      connectionRecoveryMaxInterval: 30,
      registerExpires: 120,
      displayName: username,
      contactName: username,
      delegate: {
        onConnect: onConnect,
        onDisconnect: onDisconnectRef.current,
        onRefer: onRefer,
        onInvite: onInvite,
        onMessage: onMessage,
        onNotify: onNotify,
        //@ts-ignore
        onStateChange: onStateChange,
        onRegister: onRegister,
        onSubscribe: onSubscribe,
        onReferRequest: onReferRequest,
        onRegisterRequest: onRegisterRequest,
        onSubscribeRequest: onSubscribeRequest,
        onCallHold: onCallHold,
      },
    };
  }
  async function getContactNameByPhoneNumber(
    phoneNumber: string
  ): Promise<string | undefined> {
    const internalUser = users.find((user) => user.sipNumber === phoneNumber);

    if (internalUser) {
      return internalUser.name;
    }

    const voiceMail = phoneNumber === '*98';

    if (voiceMail) {
      return 'Buzon de Voz';
    }

    const contactResponse = await dispatch(
      fetchContacts({
        currentPage: 1,
        perPage: 1,
        order: 'ASC',
        orderColumn: 'name',
        format: 'IContact',
        operator: 'AND',
        filter: [{ column: 'Phone', condition: 'equal', value: phoneNumber }],
      })
    );

    //@ts-ignore
    const contact = contactResponse.payload?.contactos[0];

    if (contact) {
      return contact.name;
    }
    return phoneNumber;
  }
  async function initUA() {
    let config = generateConfig(extension, password);
    const ua = new SIP.UserAgent(config);

    // @ts-ignore
    window.trii = {};
    // @ts-ignore
    window.trii.softphone = {};

    ua.start()
      .then(() => {
        const registerer = new SIP.Registerer(ua);
        registerer.register();

        // @ts-ignore
        window.trii.softphone.registerer = registerer;

        setConfig(config);
        setUserAgent(ua);
        console.log('Usuario SIP iniciado');
      })
      .catch((error) => {
        console.error('Error al iniciar el usuario SIP:', error);
      });
  }
  function stopUa() {
    if (userAgent) {
      setManuallyStoped(true);
      userAgent.stop();
    }
  }
  // Reactions to session events
  async function onInvite(session) {
    let currentSession = session as SIP.Inviter;

    const inviterNumber = session.request.from.uri.normal.user;
    const inviterIdentification = await getContactNameByPhoneNumber(inviterNumber);

    setInviterInfo({ name: inviterIdentification, number: inviterNumber });
    setSession(currentSession);

    currentSession.stateChange.addListener((newState) => {
      // Este listener solo escucha a partir de el momento en el cual
      // la llamada se esta empezando a establecer (aceptan la invitacion),
      // si no esta establecida no se ejecuta.
      console.log('session change: ' + newState);
      switch (newState) {
        case SIP.SessionState.Establishing:
          break;
        case SIP.SessionState.Established:
          setCallStatus(CallStatus.Answered);
          setupRemoteMedia(currentSession);
          break;
        case SIP.SessionState.Terminated:
          setCallStatus(CallStatus.Idle);
          setSession(null);
          break;
        default:
          break;
      }
    });

    setCallStatus(CallStatus.Ringing);
    setSession(currentSession);
  }
  async function onConnect() {
    setTransportStatus('connected');
    console.log('onConnect');

    // Clear the interval when connected
    if (reconnectIntervalId) {
      clearInterval(reconnectIntervalId);
      reconnectIntervalId = null;
      reconnectStartTime = null;
    }
  }
  function onDisconnect(data) {
    console.log('onDisconnect', data);

    setTransportStatus('disconnected');

    // Clear any existing interval
    if (reconnectIntervalId) {
      clearInterval(reconnectIntervalId);
    }
    console.log(
      'useUserAgent onDisconnect: manuallyStoppedRef.current',
      manuallyStoppedRef.current
    );
    if (manuallyStoppedRef.current) {
      return;
    }

    // Record the start time of reconnection attempts
    reconnectStartTime = Date.now();

    // Try to reconnect every 10 seconds
    reconnectIntervalId = setInterval(() => {
      // If 5 minutes have passed, stop trying to reconnect
      if (Date.now() - reconnectStartTime >= 5 * 60 * 1000) {
        clearInterval(reconnectIntervalId);
        reconnectIntervalId = null;
        reconnectStartTime = null;
      } else {
        initUA();
      }
    }, 10000);
  }

  useEffect(() => {
    onDisconnectRef.current = onDisconnect;
  }, [onDisconnect]);

  useEffect(() => {
    manuallyStoppedRef.current = manuallyStopped;
  }, [manuallyStopped]);

  useEffect(() => {
    userAgentRef.current = userAgent;
  }, [userAgent]);

  useEffect(() => {
    if (extension && password && userAgent === null) {
      const initializeUA = async () => {
        await initUA();
      };
      initializeUA();
    }
  }, [password, extension]);

  return {
    userAgent,
    makeCall,
    endCall,
    endTransferCall,
    callStatus,
    session,
    acceptCall,
    blindTransferCall,
    attendedTransferCall,
    hold,
    unhold,
    holdStatus,
    inviterInfo,
    sendDTMF,
    mute,
    unmute,
    muteStatus,
    transportStatus,
    setupRemoteMedia,
    stopUa,
    transferCallStatus,
    completeAttendedTransfer,
  };
};
export default useUserAgent;
