// Components/ui
import { IconButton } from '@mui/material';
import { style } from './style';
import HorizontalRuleRoundedIcon from '@mui/icons-material/HorizontalRuleRounded';
// Context
import { useContext } from 'react';
import SipContext from '../../../../../../../../../../../../../../../../context/Sip/SipContext';

const CloseButton = () => {
  const { handleHangUpTransferCall } = useContext(SipContext);
  return (
    <IconButton
      size="small"
      onClick={handleHangUpTransferCall}
      sx={{ alignSelf: 'center' }}
    >
      <HorizontalRuleRoundedIcon sx={style.icon} />
    </IconButton>
  );
};

export default CloseButton;
