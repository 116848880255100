import React from 'react';
// Components/ui
import { TextField, IconButton, useTheme } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';

const Footer = () => {
  const theme = useTheme();

  return (
    <div
      style={{
        width: '100%',
        borderTop: `1px solid ${theme.palette.divider}`,
        display: 'flex',
        height: '45px',
        alignItems: 'center',
      }}
    >
      <TextField
        fullWidth
        size="small"
        variant="outlined"
        placeholder="Type a message"
        sx={{
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: 'transparent',
            },
            '&:hover fieldset': {
              borderColor: 'transparent',
            },
            '&.Mui-focused fieldset': {
              borderColor: 'transparent',
            },
          },
        }}
      />
      <IconButton disableRipple>
        <SendIcon sx={{ fontSize: 15 }} />
      </IconButton>
    </div>
  );
};

export default Footer;
