// Context
import { useContext } from 'react';
import SipContext from '../../../../../../../../../../context/Sip/SipContext';
// Components/ui
import { Box, IconButton, Typography } from '@mui/material';
import CallIcon from '@mui/icons-material/Call';
import { style } from './style';
const VoiceMailCall = () => {
  const { handleMakeVoiceMailCall } = useContext(SipContext);

  return (
    <Box sx={style.container}>
      <Typography color={'text.primary'}>Buzon de voz</Typography>
      <IconButton onClick={handleMakeVoiceMailCall}>
        <CallIcon />
      </IconButton>
    </Box>
  );
};

export default VoiceMailCall;
