export const style = {
  container: {
    display: 'flex',
    gap: '1rem',
    position: 'absolute',
    bottom: '12rem',
    right: '0rem',
    zIndex: '100',
    alignItems: 'center',
  },
  button: {
    userSelect: 'none',
    display: 'flex',
    alignItems: 'center',
    minHeight: '6rem',
    borderTopLeftRadius: '10px',
    borderBottomLeftRadius: '10px',
    backgroundColor: 'primary.main',
    cursor: 'pointer',
    transition: 'all 0.15s ease-in-out',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  textContainer: {
    transform: 'rotate(90deg)',
    borderTop: '0.2rem solid white',
    paddingTop: '0.1rem',
  },
  drawer: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    backgroundColor: 'primary.light',
    zIndex: '99',
    display: 'flex',
    gap: '0.5rem',
    padding: '0.5rem',
    borderTopLeftRadius: '10px',
    borderBottomLeftRadius: '10px',
  },
};
