import { useCallback } from 'react';
import { useLocation } from 'react-router-dom';

export const useModuleEvent = () => {
  // const location = useLocation();

  // Function to get the module name from the event prefix
  const getModuleNameForEvent = (eventName: string): string | null => {
    const [prefix] = eventName.split('_');

    if (prefix === 'conversation') {
      return 'conversations';
    } else if (prefix === 'chat') {
      return 'chat';
    }

    return null;
  };

  // Function to check if the current URL contains the module name
  const doesUrlContainModule = (eventName: string): boolean => {
    const moduleName = getModuleNameForEvent(eventName);
    if (!moduleName) {
      return false;
    }

    // Ensure the module name is in the correct part of the URL (after "/a/")
    const moduleInUrl = window.location.pathname.split('/a/')[1];

    return moduleInUrl?.startsWith(moduleName);
  };

  return {
    getModuleNameForEvent,
    doesUrlContainModule,
  };
};
