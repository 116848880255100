import {
  createSlice,
  createAsyncThunk,
  createListenerMiddleware,
} from '@reduxjs/toolkit';
// Utils
import initRequestData from '../../functions/initRequestData';
import service from './service';
// Types
import { PayloadAction } from '@reduxjs/toolkit';
import { UsersSlice } from './types/UsersSlice';
import InitRequestDataReturn from '../../types/InitRequestDataReturn';
import { RootState } from '../../rootReducer';
import { UserPresence, UserStatus } from '@trii/types/dist/Users';

const initialState: UsersSlice = {
  status: {
    fetch: 'idle',
  },
  users: [],
};

export const fetchUsers = createAsyncThunk(
  'Users/fetchUsers',
  async (_, { dispatch, getState }) => {
    const { jwtToken, URL_USERS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    const { User } = getState() as RootState;

    const users = await service.fetch(jwtToken, URL_USERS);
    const filteredUsers = users.filter((user) => user.email !== User.user.email);

    return filteredUsers;
  }
);

const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    updateUserPresence: (state, action: PayloadAction<UserPresence>) => {
      const { uid } = action.payload;
      const user = state.users.find((user) => user.id === uid);

      if (user) {
        user.status = action.payload.status;
        user.statusMessage = action.payload.statusMessage;
        user.statusLastSeen = action.payload.statusLastSeen;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUsers.pending, (state) => {
      state.status.fetch = 'loading';
    });
    builder.addCase(fetchUsers.fulfilled, (state, action) => {
      state.status.fetch = 'succeeded';
      console.log('Succeeded fetchUsers:', action.payload);
      state.users = action.payload;
    });
    builder.addCase(fetchUsers.rejected, (state, action) => {
      console.error('Rejected fetchUsers: ', action.error);
      state.status.fetch = 'rejected';
    });
  },
});

export const { updateUserPresence } = usersSlice.actions;

export default usersSlice.reducer;
