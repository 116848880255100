export const style = {
  container: { overflow: 'auto', width: '100%' },
  formControlLabel: { userSelect: 'none', marginBottom: '5px', paddingLeft: '10px' },
  label: {
    display: 'flex',
    flexDirection: 'column',
    gap: '2px',
    width: '100%',
  },
};
