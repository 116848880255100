import { keyframes } from '@mui/material';

export const style = {
  container: {
    position: 'absolute',
    bottom: '1.4rem',
    right: '0rem',
    zIndex: '100',
    height: '100vh',
    boxShadow: '-8px 0px 8px 0px rgba(0,0,0,0.25)',
    paddingTop: '4.8rem',
    backgroundColor: 'background.default',
  },
};
export const slideIn = keyframes`
  0% {
    width: 0;
  }
  100% {
    width: 20rem;
  }
`;

export const slideOut = keyframes`
  0% {
    width: 20rem;
  }
  100% {
    width: 0;
  }
`;
